@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css");
@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";
@import url(../assets/plugin/tab/tabby-ui.css);
@import url(../assets/styles/bootstrap.css);
@import url(../assets/plugin/bootstrap/css/bootstrap.min.css);
@import url(../assets//plugin/nprogress/nprogress.css);
@import url(../assets/styles/style.css);
@import url(../assets/styles/style-m.css);
@import url(../assets/styles/style-u.css);
@import url(../assets/styles/responsive.css);

.knob-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.multi-select {
  width: 230px;
  margin: 0 auto;
}

.searchBox {
  // display: none;
  position: absolute;
  left: 0;
  opacity: 0;
  z-index: -1;
}

.error-input-show {
  color: #ff0000;
  display: block;
  .hide {
    display: none;
  }
}
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 1000;
  .spinner-border {
    position: relative;
    width: 40px;
    height: 40px;
  }
}
.show.fade {
  opacity: 1;
}

.circle {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 6px;
  &.red {
    background-color: #ff0000;
  }
  &.amber {
    background-color: #ffbf00;
  }
  &.yellow {
    background-color: #ee7d32;
  }
}
.risk {
  &.red {
    background-color: #ff0000;
  }
  &.amber {
    background-color: #ffbf00;
  }
  &.green {
    background-color: #3ba310;
  }
}
.input_autotype {
  margin: 0 0 15px;
}

.criteria_select_options {
  #multiselectContainerReact {
    > div {
      padding: 0;
      border: none;
      span {
        i {
          font-size: 12px;
          color: #8f8f8f;
        }
      }
    }
  }
}

.sidebar_btn_analyse {
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 15px;
  text-align: center;
  border: 1px solid #fff;
  padding: 6px 12px;
  border-radius: 4px;
  margin-bottom: 20px;
}

.rw-multiselect-tag-btn {
  span {
    background-color: #8f8f8f;
    width: 14px;
    height: 14px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    border-radius: 50%;
    font-weight: bold;
  }
  .rw-input-reset {
  }
}

.rw-multiselect > .rw-widget-picker {
  // padding: 8px;
}

.criteria_select_options ul,
.sidebar_criteria_all_options ul {
  width: 100%;
  li {
    line-height: 1.54;
  }
}
.rw-select {
  display: none !important;
}

.side_bar_content {
  .rw-widget-container {
    border: none !important;
    box-shadow: none !important;
  }
}

.rw-list-empty {
  display: none !important;
}

.add-keyword-message {
  // padding: 10px 10px 0;
  text-align: center;
  font-size: 12px;
  color: #666;
}

.add-keyword-section {
  padding: 10px;
}

.tbl-sector-detection td {
  border: none !important;
  padding: 20px 0 0 !important;
}

.small-heading {
  color: #252524 !important;
}

.company_points {
  margin-top: 30px;
}

.company_points .point-number {
  min-width: 80px;
  height: 80px;
  font-size: 40px;
  font-weight: 700;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #28282c;
  margin-right: 15px;
}

.company_point-box {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.company_point-box .point-disc {
  font-size: 22px;
  color: #252524;
}

.company-profile-keyMetrics {
  margin-bottom: 80px !important;
}

.company-profile-keyMetrics.box_content_outer {
  padding: 50px 40px;
}

.company-profile-keyMetrics ul {
  display: flex;
}

.company-profile-keyMetrics li {
  flex-grow: 1;
  border-right: 1px solid #dddddd;
  padding-left: 30px;
}

.company-profile-keyMetrics li h1 {
  margin-top: 0;
}

.company-profile-keyMetrics li p {
  max-width: 230px;
  min-height: 0;
  margin-bottom: 0;
}

.company-profile-keyMetrics li:first-child {
  padding-left: 0;
}

.company-profile-keyMetrics li:last-child {
  border-right: none;
}

.tbl-portfolio tr th {
  font-size: 13px;
  text-transform: uppercase;
  color: #8f8f8f;
}

.tbl-portfolio tbody tr td {
  font-size: 16px;
}

.tbl-portfolio > tbody > tr > td,
.tbl-portfolio > tbody > tr > th,
.tbl-portfolio > tfoot > tr > td,
.tbl-portfolio > tfoot > tr > th,
.tbl-portfolio > thead > tr > td,
.tbl-portfolio > thead > tr > th {
  padding: 20px 15px;
}

.grid-status {
  width: 40px;
  display: inline-block;
  padding: 3px;
  border-radius: 4px;
}

.grid-status.danger {
  background-color: #ffe2df;
  color: #ff5f49;
}

.grid-status.success {
  background-color: #e5f6e9;
  color: #2be256;
}

.exclusion-risk {
  display: flex;
  align-items: center;
}

.color-bar {
  display: flex;
  align-items: center;
  border-radius: 100px;
  overflow: hidden;
  min-width: 150px;
  height: 10px;
}

.color-bar span {
  flex: 1;
  width: 100%;
  display: flex;
  height: 100%;
  cursor: pointer;
}

.color-bar span.red {
  background-color: #ff3c3c;
}

.color-bar span.yellow {
  background-color: #ffbc00;
}

.color-bar span.green {
  background-color: #3de464;
}

.exclusion-risk p {
  white-space: nowrap;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.vertical-middle {
  vertical-align: middle !important;
}

.grid-pagination {
  display: flex;
  align-items: center;
}

.grid-pagination > span {
  color: #8f8f8f;
  font-weight: 600;
  text-transform: uppercase;
}

.grid-pagination .pagination > .disabled > a {
  background-color: transparent;
  border-color: #ddd;
}

.grid-pagination .pagination {
  display: flex;
}

.grid-pagination .pagination li {
  margin: 0 5px;
}

.grid-pagination .pagination > li a {
  border-radius: 4px;
  width: 35px;
  height: 34px;
  display: flex;
  font-size: 16px;
  line-height: 1;
  color: #8f8f8f;
  align-items: center;
}

.grid-pagination .pagination > .active > a {
  background-color: #fff;
  color: #8f8f8f;
  font-weight: 600;
  border-color: #fff;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.2);
}

.filter .custom-control {
  padding-left: 2.5rem;
  margin-bottom: 15px;
}
.filter .custom-control-label::before,
.filter .custom-control-label::after {
  margin-top: -1px;
  left: -2.5rem;
}
.filter .custom-control-label::before {
  border-radius: 5px !important;
}
.filter .custom-control-label {
  color: #fff;
  font-size: 16px;
}
.seprator {
  border-bottom: 1px solid #fff;
  margin: 25px 0;
}

.filter .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #fff;
  background-color: #fff;
}
.filter .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("../assets/images/checked.svg");
}

.input-icon {
  position: absolute;
  top: 13px;
  left: 10px;
  font-size: 15px;
}
.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  background-color: #f16949;
  border-color: #f16849;
}

@media screen and (max-width: 991px) {
  .company_points .point-number {
    min-width: 50px;
    height: 50px;
    font-size: 22px;
  }
  .company_point-box .point-disc {
    font-size: 18px;
  }
}

@media screen and (max-width: 767px) {
  .company_point-box {
    margin-bottom: 20px;
  }
  .company_points {
    margin-bottom: 30px;
  }
  .company-profile-keyMetrics li {
    width: 100%;
    padding: 0;
    border: none;
    margin-bottom: 30px;
  }
  .company-profile-keyMetrics li:last-child {
    margin-bottom: 0;
  }
  .company-profile-keyMetrics ul {
    flex-direction: column;
  }
  .company-profile-keyMetrics.box_content_outer {
    padding: 20px 40px;
  }
}
