.my_fixnavbar {
    color: #000;
    background-color: #fff;
    padding: 20px 20px 20px 30px;
    height: 80px;
}

.top_bar p {
    margin: 0;
    font-size: 20px;
    color: #28282c;
    font-family: "Source Sans Pro", sans-serif;
}

.top_bar p span {
    font-weight: 600;
    color: #ee7d32;
}

.top_bar p i {
    vertical-align: middle;
    font-size: 24px;
    margin: 0 10px;
    color: #ee7d32;
}

.topbar_right {
    margin: -40px 0 0 0;
}

.topbar_right i {
    vertical-align: middle;
    color: #3b3b3f;
    font-size: 20px;
    margin-right: 10px;
    cursor: pointer;
}

.topbar_right button {
    background-color: transparent;
    padding: 0;
    outline: none;
}

.topbar_right button span {
    border-radius: 50px;
    box-shadow: 3px 3px 5px 0 #f2f2f3;
    border: solid 1px #f2f2f3;
    background-color: #fff;
    color: #000;
    padding: 7px 32px;
    transition: linear all 0.2s;
}
.topbar_right button span:hover {
    background-image: linear-gradient(to bottom, #ee7d32, #ff5f49);
    border: 1px solid transparent;
    color: #fff;
}

.topbar_right button span {
    color: #000;
}

.topbar_right button:hover span {
    color: #fff;
    transition: linear all 0.2s;
}

/*Setting button start*/
.setting_outer {
    display: none !important;
}

.dash_settings:after {
    content: none;
}

.settings_content {
    padding: 20px 0;
}

/* top bar close */

/*Login Page start*/

.login_wrapper {
    background-color: #f3f3f3;
    padding: 50px;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.login_outer {
    border-radius: 7px;
    box-shadow: 5px 5px 33px 0 rgba(0, 0, 0, 0.23);
    background-color: #fff;
    padding: 50px 80px 80px;
    /*width: 500px;*/
}

.close_button i {
    font-size: 25px;
    color: #d9d9d9;
    margin: -40px 0 30px 60px;
    position: absolute;
    cursor: pointer;
}

.close_button i:hover {
    color: #000;
}

.login_outer h3 {
    font-size: 28px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #252524;
    margin: 0 0 30px;
}

.login_outer h3 span {
    color: #ee7d32;
}

.login_outer form {
    margin: 50px 0 0;
}

.login_outer form input {
    padding: 5px 40px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #8f8f8f;
    height: 42px;
    font-family: "Source Sans Pro", sans-serif;
    margin: 0 0 20px;
    border: solid 1px #d9d9d9;
    border-radius: 5px;
    background-color: var(--white);
}

.login_outer form input:focus {
    color: #495057;
    background-color: #fff;
    border-color: #ff5f49;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0);
}

.form_input i {
    position: absolute;
    margin: 12px 0 0 15px;
    color: #ff5f49;
}

.form_input .show_pw i {
    text-align: right;
    text-align: right;
    margin: 14px 0 0 -35px;
    position: absolute;
    color: #8f8f8f;
    cursor: pointer;
}

.form_input .show_pw i:hover {
    color: #000;
}

/*Buttons start*/
/*.login_outer form .btn_login , */
.login_outer form .btn_signin {
    width: 100%;
    border-radius: 5px;
    font-size: 18px;
    font-weight: bold;
    font-family: "Source Sans Pro", sans-serif;
    height: 42px;
}

.login_outer form .btn_login {
    box-shadow: 4px 4px 8px 0 rgba(238, 125, 50, 0.51) !important;
    background-image: linear-gradient(to left, #ff5f49, #ee7d32 0%) !important;
    border: 1px solid transparent;
    margin: 15px 0;
    color: #fff;
    transition: linear all 0.4s;
    /*width: 100%;*/
    font-weight: bold;
    display: block;
}

.login_outer form .btn_login span {
    padding: 0 130px;
    color: #fff;
}

.login_outer form .btn_login:hover {
    background: transparent !important;
    border: 1px solid #ee7d32;
    color: #ee7d32;
    transition: linear all 0.4s;
}

.login_outer form .btn_login:hover span {
    color: #ee7d32;
}

.btn_login {
    margin: 15px 0 !important;
}

.login_outer form small {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    margin: 15px 0 0 0;
    cursor: pointer;
}

.login_outer form small span {
    color: #8f8f8f;
}

.login_outer form small span:hover {
    color: #000;
}

.login_outer form .btn_signin {
    background: transparent;
    border: 1px solid #ee7d32;
    margin: 15px 0;
    color: #ee7d32;
    margin: 50px 0 0;
    display: block;
}

.login_outer form .btn_signin a {
    color: #ee7d32;
}

.login_outer form .btn_signin:hover {
    background: #ee7d32;
    color: #fff;
}

.login_outer form .btn_signin:hover a {
    color: #fff;
}

/*Login page close*/
/*=================*/
/*Search page start*/
/*Search page start*/
.col_nopadding {
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.search_page_content_outer {
    padding: 20px 45px;
}

.page_title {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 28px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #252524;
    margin: 0 0 30px;
}

/*left section start*/
.search_left {
    border-radius: 7px;
    box-shadow: 5px 5px 13px 0 #e1e1e1;
    background-color: #ffff;
    padding: 50px;
}
.inner-or p {
    text-align: center;
    text-transform: uppercase;
    margin: 100px 0px 0px 0px;
    font-size: 16px;
    font-weight: 600;
}
.search_left .custom-control-inline {
    width: 100%;
    border-radius: 8px;
    background: #f3f3f3;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 30px 15px 15px;
}

.search_left_input:hover {
    background-color: #fff4ed;
}

.search_left_input input [type="radio"],
.search_left_input label {
    margin: 0 0 30px auto;
}

.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #ee7d32;
    background-color: #ee7d32;
}

.custom-control-label::before,
.custom-control-label::after {
    width: 2rem;
    height: 2rem;
    margin: -15px 0 0 0;
}

.search_input {
    margin: 0;
}

.search_left_input input {
    padding: 5px 40px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #8f8f8f !important;
    height: 45px;
    font-family: "Source Sans Pro", sans-serif !important;
    border: solid 1px #d9d9d9;
    border-radius: 5px;
    background-color: var(--white);
}

.search_left_input input:focus,
.search_left_input select:focus {
    color: #495057;
    background-color: #fff;
    border-color: #ff5f49;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0);
}

.search_left_input i {
    position: absolute;
    margin: 16px 0 0 15px;
    color: #ff5f49;
}

.search_left span.input_or {
    font-size: 16px;
    font-weight: 600;
    color: #252524;
    font-family: "Source Sans Pro", sans-serif !important;
}

/*Browse stat*/
.browse_file {
    padding: 20px 30px;
    border-radius: 5px;
    border: dashed 1px #ee7d32;
    background-color: var(--white);
}

.browse_input {
    margin: 0 0 0 65px;
}

.browse_input input {
    border: 0 !important;
    padding: 0;
}

.browse_input span {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    color: #28282c;
}

.browse_icon {
    position: absolute;
}

.browse_icon i {
    position: unset;
    margin: 5px 0 0 0px;
    color: #ff5f49;
    font-size: 40px;
}

.browse_input button {
    border: solid 1px #d9d9d9;
    color: #252524;
    background-color: white;
    padding: 2px 20px;
    border-radius: 5px;
    font-family: "Source Sans Pro", sans-serif !important;
    font-size: 12px;
    font-weight: 600;
    margin: 25px 0 0;
}

.browse_input input[type="file"] {
    font-size: 15px;
    /*position: absolute;
    left: 0;
    top: 90px;*/
    opacity: 0;
    width: 100%;
    margin: -45px 0 0 0;
    cursor: pointer;
}

/*after browse input text*/
.search_left_input h6 {
    font-size: 12px;
    color: #8f8f8f;
    margin: 0px;
}
/*Radio checkbox close*/
.plus_btn_outer {
    margin: 100px 0px 0px 0px;
}
.plus_btn {
    display: flex;
    justify-content: center;
    align-items: center;
}
.plus_btn span {
    box-shadow: 4px 4px 8px 0 rgba(238, 125, 50, 0.51);
    background-image: linear-gradient(to left, #ff5f49, #ee7d32 0%);
    border: 1px solid transparent;
    color: #fff !important;
    border-radius: 6px;
    width: 40px;
    height: 40px;
    transition: linear all 0.4s;
    font-family: "Source Sans Pro", sans-serif !important;
    font-weight: 800;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 100%;
    margin-top: 15px;
}
.plus_btn span i {
    vertical-align: middle;
    color: #fff !important;
    text-align: center;
    margin: 8px;
    font-size: 16px;
}

/*right section start*/
.search_right {
    padding: 0 30px;
}

.search_tip h4 {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #252524;
    margin: 0 0 30px;
}
.search_tip h4::after {
    display: block;
    content: "";
    border-bottom: 2px solid #28282c;
    width: 200px;
    margin: -10px 0px 0px 115px;
}
.search_right ul {
    padding: 0;
    margin: 0;
}

.search_right ul li {
    list-style-type: none;
    display: flex;
    margin: 0 0 30px;
}

.search_right ul li i {
    margin: 3px 10px 0 0;
    font-size: 12px;
    color: #ff5f49;
}

.search_right ul li p {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #707077;
}

.search_right ul li p span {
    display: block;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #252524;
    margin: 0 0 10px;
}

/*Search page close*/
/*===================*/
/*exclusion_criteria*/
.exclusion_criteria {
    margin: 60px 0 0 0;
    width: 100%;
}

.exclusion_criteria h3 {
    font-family: "Source Sans Pro", sans-serif !important;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #252524;
    margin: 0 0 15px;
}

.exclusion_criteria select {
    margin: 0 0 30px;
    width: 100%;
    height: 42px;
    padding: 0 20px;
    font-size: 15px;
    font-family: "Source Sans Pro", sans-serif !important;
}

.exclusion_criteria select:focus {
    color: #495057;
    background-color: #fff;
    border-color: #ff5f49;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0);
}

.exclusion_criteria .btn_analyse span {
    box-shadow: 4px 4px 8px 0 rgba(238, 125, 50, 0.51);
    background-image: linear-gradient(to left, #ff5f49, #ee7d32 0%);
    border: 1px solid transparent;
    margin: 15px 0;
    color: #fff;
    transition: linear all 0.4s;
    font-family: "Source Sans Pro", sans-serif !important;
    font-weight: 800;
    width: 100%;
    padding: 15px;
    text-align: center;
    color: #fff !important;
    display: block;
    border-radius: 8px;
    cursor: pointer;
}

.exclusion_criteria .btn_analyse span:hover {
    background: transparent;
    border: 1px solid #ff5f49;
    color: #ff5f49 !important;
    transition: linear all 0.4s;
}

/*exclusion_criteria_right section start*/
.exclusion_criteria_right {
    overflow: auto;
    margin: 60px 0 0 0;
    border-radius: 5px;
    border: solid 1px #d9d9d9;
    background-color: var(--white);
    padding: 15px;
    height: 200px;
}

.exclusion_criteria_right_outer {
    border-radius: 5px;
    background-color: transparent;
    /*padding: 15px;*/
    border: solid 1px #d9d9d9;
}

.select_text_box {
    border-radius: 5px;
    background-color: transparent;
    padding: 15px;
    border-bottom: solid 1px #d9d9d9;
}

.select_text_box:hover {
    background-color: #fff4ed;
}

.select_text_box p {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #252524;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.select_text_box p a {
    float: right;
}

.select_text_box p i {
    vertical-align: middle;
    float: right;
    font-size: 22px;
    color: #ff5f49;
    cursor: pointer;
}

.select_text_box p i:hover {
    color: #000;
}

/*Search 2 page start */
/*Static html coding*/
.criteria_select_options {
    padding: 8px;
    border: solid 1px #d9d9d9;
    background-color: #fff;
    border-radius: 5px;
    margin: 0 0 15px;
}

.criteria_select_options ul,
.criteria_all_options ul {
    padding: 0;
    margin: 0;
}

.criteria_select_options ul li,
.criteria_all_options ul li {
    list-style-type: none;
    display: inline-block;
    margin: 0 2px 4px 0;
}

.selected_options p {
    margin: 0;
    font-size: 14px;
    color: #252524;
    width: max-content;
    background: #f0f0f0;
    padding: 5px 10px;
    border-radius: 5px;
}

.selected_options p a i,
.all_options p a i {
    margin: -4px 0 0 7px;
    font-size: 16px;
    color: #252524;
    vertical-align: middle;
}

.selected_options p a i:hover,
.all_options p a i:hover {
    color: #ccc;
}

/*Selected options close*/
/*All options start*/
.criteria_all_options {
    border-radius: 5px;
    box-shadow: 3px 3px 13px 0 rgba(143, 143, 143, 0.61);
    border: solid 1px #d9d9d9;
    background-color: #fff;
    padding: 8px;
}

.all_options p {
    margin: 0;
    font-size: 14px;
    color: #252524;
    width: max-content;
    background: transparent;
    padding: 10px 10px;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
}

.all_options p:hover {
    background-color: #ffe7d7;
}

.all_options p span i {
    color: #ff5f49;
    cursor: pointer;
}

.all_options p span i:hover {
    color: #000;
}

/*added option start*/
.added_option p {
    background-color: #e5f6e9;
    margin: 0;
    font-size: 14px;
    color: #252524;
    width: max-content;
    padding: 10px 10px;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
}

.added_option p:hover {
    background-color: #e5f6e9;
}

.added_option p span i,
.added_option p span i:hover {
    color: #5fcb76;
}

/*Search Page close*/
/*================*/
/*Summary Page start*/
.summary_page_content_outer {
    padding: 20px 45px;
}
.summary_page_content_inner {
    margin-top: 30px;
}
.summary_page_content_outer p {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #252524;
    margin: 0 0 30px;
}

.summary_page_content_outer p a {
    color: #252524;
}

.summary_page_content_outer span i {
    font-size: 12px;
    vertical-align: middle;
}

.card_graph {
    border-radius: 7px;
    box-shadow: 5px 5px 13px 0 #e1e1e1;
    background-color: #fff;
    padding: 30px;
    margin: 0 0 30px;
    min-height: 320px;
}

.card_graph_right {
    padding: 80px 30px;
    min-height: 670px;
}

.graph_title {
    font-size: 20px;
    font-weight: 500;
    font-family: "Source Sans Pro", sans-serif !important;
    margin: 0 0 30px;
}

.card_graph select {
    width: 50%;
    margin: 0 0 90px;
    padding: 0 15px;
    height: 42px;
}

.card_graph select:focus {
    background-color: #fff;
    border-color: #ff5f49;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0);
}

/*=====================================================*/
/*Multiple select input */

.selectMultiple {
    width: 100%;
    position: relative;
    margin: 0 0 30px;
}
.selectMultiple select {
    display: none;
}
.selectMultiple > div {
    position: relative;
    z-index: 2;
    padding: 8px 12px 2px 12px;
    border-radius: 8px;
    background: #fff;
    font-size: 14px;
    min-height: 44px;
    /*box-shadow: 0 4px 16px 0 rgba(22, 42, 90, .12);*/
    border: solid 1px #d9d9d9;
    transition: box-shadow 0.3s ease;
}
.selectMultiple > div:hover {
    /*box-shadow: 0 4px 24px -1px rgba(22, 42, 90, .16);*/
}
.selectMultiple > div .arrow {
    right: 1px;
    top: 0;
    bottom: 0;
    cursor: pointer;
    width: 28px;
    position: absolute;
}
.selectMultiple > div .arrow:before,
.selectMultiple > div .arrow:after {
    content: "";
    position: absolute;
    display: block;
    width: 2px;
    height: 8px;
    border-bottom: 8px solid #99a3ba;
    top: 43%;
    transition: all 0.3s ease;
}
.selectMultiple > div .arrow:before {
    right: 12px;
    transform: rotate(-130deg);
}
.selectMultiple > div .arrow:after {
    left: 9px;
    transform: rotate(130deg);
}
.selectMultiple > div span {
    color: #99a3ba;
    display: block;
    position: absolute;
    left: 12px;
    cursor: pointer;
    top: 8px;
    line-height: 28px;
    transition: all 0.3s ease;
    width: 100% !important;
}
.selectMultiple > div span.hide {
    opacity: 0;
    visibility: hidden;
    transform: translate(-4px, 0);
}
.selectMultiple > div a {
    position: relative;
    padding: 0 24px 6px 8px;
    line-height: 28px;
    color: #000;
    display: inline-block;
    vertical-align: top;
    margin: 0 6px 0 0;
}
.selectMultiple > div a em {
    font-style: normal;
    display: block;
    white-space: nowrap;
}
.selectMultiple > div a:before {
    content: "";
    left: 0;
    top: 0;
    bottom: 6px;
    width: 100%;
    position: absolute;
    display: block;
    background: rgba(228, 236, 250, 0.7);
    z-index: -1;
    border-radius: 4px;
}
.selectMultiple > div a i {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    width: 24px;
    height: 28px;
    display: block;
}
.selectMultiple > div a i:before,
.selectMultiple > div a i:after {
    content: "";
    display: block;
    width: 2px;
    height: 10px;
    position: absolute;
    left: 50%;
    top: 50%;
    background: #ccc;
    border-radius: 1px;
}
.selectMultiple > div a i:before {
    transform: translate(-50%, -50%) rotate(45deg);
}
.selectMultiple > div a i:after {
    transform: translate(-50%, -50%) rotate(-45deg);
}
.selectMultiple > div a.notShown {
    opacity: 0;
    transition: opacity 0.3s ease;
}
.selectMultiple > div a.notShown:before {
    width: 28px;
    transition: width 0.45s cubic-bezier(0.87, -0.41, 0.19, 1.44) 0.2s;
}
.selectMultiple > div a.notShown i {
    opacity: 0;
    transition: all 0.3s ease 0.3s;
}
.selectMultiple > div a.notShown em {
    opacity: 0;
    transform: translate(-6px, 0);
    transition: all 0.4s ease 0.3s;
}
.selectMultiple > div a.notShown.shown {
    opacity: 1;
}
.selectMultiple > div a.notShown.shown:before {
    width: 100%;
}
.selectMultiple > div a.notShown.shown i {
    opacity: 1;
}
.selectMultiple > div a.notShown.shown em {
    opacity: 1;
    transform: translate(0, 0);
}
.selectMultiple > div a.remove:before {
    width: 28px;
    transition: width 0.4s cubic-bezier(0.87, -0.41, 0.19, 1.44) 0s;
}
.selectMultiple > div a.remove i {
    opacity: 0;
    transition: all 0.3s ease 0s;
}
.selectMultiple > div a.remove em {
    opacity: 0;
    transform: translate(-12px, 0);
    transition: all 0.4s ease 0s;
}
.selectMultiple > div a.remove.disappear {
    opacity: 0;
    transition: opacity 0.5s ease 0s;
}
.selectMultiple > ul {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 16px;
    z-index: 1;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    visibility: hidden;
    opacity: 0;
    border-radius: 8px;
    transform: translate(0, 20px) scale(0.8);
    transform-origin: 0 0;
    filter: drop-shadow(0 12px 20px rgba(22, 42, 90, 0.08));
    transition: all 0.4s ease, transform 0.4s cubic-bezier(0.87, -0.41, 0.19, 1.44), filter 0.3s ease 0.2s;
}
.selectMultiple > ul li {
    color: #000;
    background: #fff;
    padding: 12px 16px;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    transition: background 0.3s ease, color 0.3s ease, transform 0.3s ease 0.3s, opacity 0.5s ease 0.3s,
        border-radius 0.3s ease 0.3s;
}
.selectMultiple > ul li:first-child {
    border-radius: 8px 8px 0 0;
}
.selectMultiple > ul li:first-child:last-child {
    border-radius: 8px;
}
.selectMultiple > ul li:last-child {
    border-radius: 0 0 8px 8px;
}
.selectMultiple > ul li:last-child:first-child {
    border-radius: 8px;
}
.selectMultiple > ul li:hover {
    background: #ccc;
    color: #fff;
}
.selectMultiple > ul li:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 6px;
    height: 6px;
    background: rgba(0, 0, 0, 0.4);
    opacity: 0;
    border-radius: 100%;
    transform: scale(1, 1) translate(-50%, -50%);
    transform-origin: 50% 50%;
}
.selectMultiple > ul li.beforeRemove {
    border-radius: 0 0 8px 8px;
}
.selectMultiple > ul li.beforeRemove:first-child {
    border-radius: 8px;
}
.selectMultiple > ul li.afterRemove {
    border-radius: 8px 8px 0 0;
}
.selectMultiple > ul li.afterRemove:last-child {
    border-radius: 8px;
}
.selectMultiple > ul li.remove {
    transform: scale(0);
    opacity: 0;
}
.selectMultiple > ul li.remove:after {
    animation: ripple 0.4s ease-out;
}
.selectMultiple > ul li.notShown {
    display: none;
    transform: scale(0);
    opacity: 0;
    transition: transform 0.35s ease, opacity 0.4s ease;
}
.selectMultiple > ul li.notShown.show {
    transform: scale(1);
    opacity: 1;
}
.selectMultiple.open > div {
    box-shadow: 0 4px 20px -1px rgba(22, 42, 90, 0.12);
}
.selectMultiple.open > div .arrow:before {
    transform: rotate(-50deg);
}
.selectMultiple.open > div .arrow:after {
    transform: rotate(50deg);
}
.selectMultiple.open > ul {
    transform: translate(0, 12px) scale(1);
    opacity: 1;
    visibility: visible;
    filter: drop-shadow(0 16px 24px rgba(22, 42, 90, 0.16));
}
