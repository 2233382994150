/* Include variables for LESS */

/* Import file styles */

@import 'https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700';


@import url(../plugin/bootstrap/css/bootstrap.min.css);

@import url(../plugin/nprogress/nprogress.css);

/* General css */

/*

Version 2.0

[Table of contents]

-------------------------------------------------------------------------

1. Globally Applied Styles

2. Layout/Boxed

3. Hover

4. Support Class

-------------------------------------------------------------------------*/

/*-----------------------------------------------------------------------

1. Globally Applied Styles

*/

html,

body {

  position: relative;

  height: 100%;

  background: #f5f7fa;

  color: #505458;

}

#wrapper {

  position: relative;

  top: 0px;

  z-index: 10;

  width: 100%;

  min-height: 100%;

  transform: translate(0, 0);

  -webkit-transform: translate(0, 0);

  -moz-transform: translate(0, 0);

  -o-transform: translate(0, 0);

  -ms-transform: translate(0, 0);

  background: #f3f3f3;

  overflow: hidden;

  transition: transform 0.4s ease;

  -webkit-transition: -webkit-transform 0.4s ease;

  -moz-transition: -moz-transform 0.4s ease;

  -o-transition: -o-transform 0.4s ease;

  -ms-transition: -ms-transform 0.4s ease;

}

@media (min-width: 1025px) {

  #wrapper {

    top: 0px !important;

  }

}

img {

  max-width: 100%;

  height: auto;

  -webkit-backface-visibility: hidden;

}

input,

button,

a {

  transition: all 0.4s ease;

  -moz-transition: all 0.4s ease;

  -o-transition: all 0.4s ease;

  -ms-transition: all 0.4s ease;

  -webkit-transition: all 0.4s ease;

}

a {

  text-decoration: none !important;

  outline: none;

  color: #1d84df;

}

a:hover,

a:active,

a:focus {

  text-decoration: none !important;

  outline: none;

  color: #304ffe;

}

input,

button {

  -webkit-border-radius: 0px;

  -moz-border-radius: 0px;

  border-radius: 0px;

  outline: none;

}

label {

  font-weight: 500;

  margin-bottom: 10px;

}

/* Animation */

@-moz-keyframes pulse {

  0% {

    -moz-transform: scale(0);

    opacity: 0.0;

  }

  25% {

    -moz-transform: scale(0);

    opacity: 0.1;

  }

  50% {

    -moz-transform: scale(0.1);

    opacity: 0.3;

  }

  75% {

    -moz-transform: scale(0.5);

    opacity: 0.5;

  }

  100% {

    -moz-transform: scale(1);

    opacity: 0.0;

  }

}

@-webkit-keyframes pulse {

  0% {

    -webkit-transform: scale(0);

    opacity: 0.0;

  }

  25% {

    -webkit-transform: scale(0);

    opacity: 0.1;

  }

  50% {

    -webkit-transform: scale(0.1);

    opacity: 0.3;

  }

  75% {

    -webkit-transform: scale(0.5);

    opacity: 0.5;

  }

  100% {

    -webkit-transform: scale(1);

    opacity: 0.0;

  }

}

/* Class */

.fa-hover {

  font-size: 14px;

  line-height: 22px;

  padding-top: 5px;

  padding-bottom: 5px;

  margin-bottom: 5px;

  overflow: hidden;

  text-overflow: ellipsis;

  white-space: nowrap;

  transition: all 0.4s ease;

  -moz-transition: all 0.4s ease;

  -o-transition: all 0.4s ease;

  -ms-transition: all 0.4s ease;

  -webkit-transition: all 0.4s ease;

  cursor: pointer;

}

.fa-hover .ico,

.fa-hover .fa {

  display: inline-block;

  height: 22px;

  vertical-align: top;

  min-width: 28px;

  font-size: 14px;

  line-height: 22px;

  transition: all 0.4s ease;

  -moz-transition: all 0.4s ease;

  -o-transition: all 0.4s ease;

  -ms-transition: all 0.4s ease;

  -webkit-transition: all 0.4s ease;

  color: #435966;

}

.fa-hover:hover {

  background: #1d84df;

  color: #ffffff;

}

.fa-hover:hover .ico,

.fa-hover:hover .fa {

  font-size: 22px;

  line-height: 22px;

  height: 22px;

  color: #ffffff;

}

.input-no-style {

  border: none;

  background: none;

  outline: none;

}

/*---------------------------------------------------------------------*/

/*-----------------------------------------------------------------------

2. Layout/Boxed

*/

.row-inline-block {

  font-size: 0px;

  text-align: left;

}

.row-inline-block > * {

  display: inline-block;

  float: none;

  font-size: 14px;

  vertical-align: top;

}

.row-inline-block.text-center > * {

  text-align: center;

}

.row.small-spacing {

  margin-left: -10px;

  margin-right: -10px;

}

.row.small-spacing > *[class^="col-"] {

  padding-left: 10px;

  padding-right: 10px;

}

/*---------------------------------------------------------------------*/

/*-----------------------------------------------------------------------

3. Hover

*/

.light-effect {

  position: relative;

  overflow: hidden;

  display: block;

}

.light-effect:after {

  content: "";

  display: block;

  position: absolute;

  top: 0px;

  left: -200%;

  width: 100%;

  height: 100%;

  background: rgba(255, 255, 255, 0.3);

  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";

  -moz-opacity: 1;

  -khtml-opacity: 1;

  opacity: 1;

  transition: all 0.4s ease;

  -moz-transition: all 0.4s ease;

  -o-transition: all 0.4s ease;

  -ms-transition: all 0.4s ease;

  -webkit-transition: all 0.4s ease;

  transform: skew(45deg);

  -webkit-transform: skew(45deg);

  -moz-transform: skew(45deg);

  -o-transform: skew(45deg);

  -ms-transform: skew(45deg);

}

.light-effect:hover:after {

  left: 100%;

  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";

  -moz-opacity: 0;

  -khtml-opacity: 0;

  opacity: 0;

}

/*---------------------------------------------------------------------*/

/*-----------------------------------------------------------------------

4. Support Class

*/

.error {

  color: #ea4335;

  font-weight: 400;

  padding: 5px 0px 5px 0px;

}

.display-none {

  display: none;

}

@media (min-width: 1025px) {

  .hidden-on-desktop {

    display: none!important;

  }

}

h4 strong {

  font-weight: 600;

  font-size: 16px;

}

.help-block {

  margin-top: 10px;

  color: #aab2bd;

}

.margin-top-0 {

  margin-top: 0px !important;

}

.margin-top-10 {

  margin-top: 10px !important;

}

.margin-top-15 {

  margin-top: 15px !important;

}

.margin-top-20 {

  margin-top: 20px !important;

}

.margin-top-30 {

  margin-top: 30px !important;

}

.margin-top-40 {

  margin-top: 40px !important;

}

.margin-top-50 {

  margin-top: 50px !important;

}

.margin-top-60 {

  margin-top: 60px !important;

}

.margin-top-70 {

  margin-top: 70px !important;

}

.margin-top-80 {

  margin-top: 80px !important;

}

.margin-top-90 {

  margin-top: 90px !important;

}

.margin-top-100 {

  margin-top: 100px !important;

}

.margin-bottom-0 {

  margin-bottom: 0px !important;

}

.margin-bottom-10 {

  margin-bottom: 10px !important;

}

.margin-bottom-20 {

  margin-bottom: 20px !important;

}

.margin-bottom-30 {

  margin-bottom: 30px !important;

}

.margin-bottom-40 {

  margin-bottom: 40px !important;

}

.margin-bottom-50 {

  margin-bottom: 50px !important;

}

.margin-bottom-60 {

  margin-bottom: 60px !important;

}

.margin-bottom-70 {

  margin-bottom: 70px !important;

}

.margin-bottom-80 {

  margin-bottom: 80px !important;

}

.margin-bottom-90 {

  margin-bottom: 90px !important;

}

.margin-bottom-100 {

  margin-bottom: 100px !important;

}

.padding-for-mess {

  padding: 10px 15px 10px 15px;

}

.padding-10 {

  padding: 10px !important;

}

.padding-20 {

  padding: 20px !important;

}

.padding-30 {

  padding: 30px !important;

}

.min-height-100 {

  min-height: 100px !important;

}

.min-height-200 {

  min-height: 200px !important;

}

.min-height-300 {

  min-height: 300px !important;

}

.min-height-400 {

  min-height: 400px !important;

}

.min-height-500 {

  min-height: 500px !important;

}

.no-margin {

  margin: 0px !important;

}

.no-padding {

  padding: 0px !important;

}

.inline-block {

  display: inline-block;

}

.btn-group-lg > .btn,

.btn-lg {

  border-radius: 3px;

  -moz-border-radius: 3px;

  -webkit-border-radius: 3px;

}

.btn {

  background: #e4e7ea;

  outline: none !important;

  font-size: 16px;

  line-height: 26px;

  padding: 8px 25px;

  border: none;

  font-weight: 500;

  box-shadow: none!important;

  -moz-box-shadow: none!important;

  -webkit-box-shadow: none!important;

}

.btn.btn-bordered {

  background: #ffffff;

  color: #435966;

  border: 2px solid #e4e7ea;

}

.btn.btn-bordered:hover {

  background: #e4e7ea;

}

.btn.btn-rounded {

  border-radius: 23px;

  -moz-border-radius: 23px;

  -webkit-border-radius: 23px;

}

.btn.btn-rounded.btn-lg {

  border-radius: 32px;

  -moz-border-radius: 32px;

  -webkit-border-radius: 32px;

}

.btn.btn-rounded.btn-sm {

  border-radius: 19px;

  -moz-border-radius: 19px;

  -webkit-border-radius: 19px;

}

.btn.btn-lg {

  font-size: 18px;

  line-height: 28px;

  padding: 15px 35px;

}

.btn.btn-sm {

  font-size: 14px;

  line-height: 24px;

  padding: 7px 20px;

}

.btn.btn-xs {

  font-size: 12px;

  line-height: 22px;

  padding: 5px 15px;

}

.btn.btn-icon {

  position: relative;

  overflow: hidden;

}

.btn.btn-icon .ico {

  position: absolute;

  top: 0;

  width: 42px;

  height: 100%;

  line-height: 42px;

  background: rgba(0, 0, 0, 0.1);

}

.btn.btn-icon.btn-lg .ico {

  line-height: 58px;

}

.btn.btn-icon.btn-sm .ico {

  line-height: 38px;

  width: 38px;

}

.btn.btn-icon.btn-xs .ico {

  line-height: 32px;

  width: 32px;

}

.btn.btn-icon-left {

  padding-left: 57px;

  padding-right: 15px;

}

.btn.btn-icon-left.btn-lg {

  padding-right: 30px;

  padding-left: 73px;

}

.btn.btn-icon-left .ico {

  left: 0;

}

.btn.btn-icon-left.btn-sm {

  padding-left: 53px;

}

.btn.btn-icon-left.btn-xs {

  padding-left: 47px;

}

.btn.btn-icon-right {

  padding-right: 57px;

  padding-left: 15px;

}

.btn.btn-icon-right.btn-lg {

  padding-left: 30px;

  padding-right: 73px;

}

.btn.btn-icon-right .ico {

  right: 0;

}

.btn.btn-icon-right.btn-sm {

  padding-right: 53px;

}

.btn.btn-icon-right.btn-xs {

  padding-right: 47px;

}

.btn.btn-circle {

  padding-right: 0;

  padding-left: 0;

  width: 42px;

  border-radius: 50%;

  -moz-border-radius: 50%;

  -webkit-border-radius: 50%;

}

.btn.btn-circle.btn-lg {

  width: 58px;

}

.btn.btn-circle.btn-sm {

  width: 38px;

}

.btn.btn-circle.btn-xs {

  width: 32px;

}

.btn.btn-social {

  width: 38px;

  height: 38px;

  padding: 0;

  line-height: 38px;

  color: #ffffff;

}

.btn.btn-facebook {

  background-color: #3b5998 !important;

}

.btn.btn-twitter {

  background-color: #55acee !important;

}

.btn.btn-google-plus {

  background-color: #dd4b39 !important;

}

.btn.btn-linkedin {

  background-color: #007bb6 !important;

}

.btn.btn-instagram {

  background-color: #3f729b !important;

}

.btn.btn-pinterest {

  background-color: #cb2027 !important;

}

.btn.btn-dribbble {

  background-color: #ea4c89 !important;

}

.btn.btn-youtube {

  background-color: #bb0000 !important;

}

.btn.btn-social-with-text {

  width: 100%;

  margin-bottom: 15px;

}

.btn .ico {

  display: inline-block;

}

.btn .ico-left {

  margin-right: 8px;

}

.btn .ico-right {

  margin-left: 8px;

}

.btn-primary {

  background: #1d84df;

}

.btn-primary:hover,

.btn-primary:active,

.btn-primary:focus {

  background: #304ffe !important;

}

.btn-primary.btn-bordered {

  border-color: #1d84df;

  color: #1d84df;

}

.btn-primary.btn-bordered:hover,

.btn-primary.btn-bordered:active,

.btn-primary.btn-bordered:focus {

  border-color: #304ffe;

  color: #ffffff;

}

.btn-success {

  background: #00bf4f;

}

.btn-success:hover,

.btn-success:active,

.btn-success:focus {

  background: #00ce55 !important;

}

.btn-success.btn-bordered {

  border-color: #00bf4f;

  color: #00bf4f;

}

.btn-success.btn-bordered:hover,

.btn-success.btn-bordered:active,

.btn-success.btn-bordered:focus {

  border-color: #00ce55;

  color: #ffffff;

}

.btn-info {

  background: #00aeff;

}

.btn-info:hover,

.btn-info:active,

.btn-info:focus {

  background: #00aeff !important;

}

.btn-info.btn-bordered {

  border-color: #00aeff;

  color: #00aeff;

}

.btn-info.btn-bordered:hover,

.btn-info.btn-bordered:active,

.btn-info.btn-bordered:focus {

  border-color: #00aeff;

  color: #ffffff;

}

.btn-warning {

  background: #fbbc05;

}

.btn-warning:hover,

.btn-warning:active,

.btn-warning:focus {

  background: #ffa000 !important;

}

.btn-warning.btn-bordered {

  border-color: #fbbc05;

  color: #fbbc05;

}

.btn-warning.btn-bordered:hover,

.btn-warning.btn-bordered:active,

.btn-warning.btn-bordered:focus {

  border-color: #ffa000;

  color: #ffffff;

}

.btn-danger {

  background: #ea4335;

}

.btn-danger:hover,

.btn-danger:active,

.btn-danger:focus {

  background: #ff3838 !important;

}

.btn-danger.btn-bordered {

  border-color: #ea4335;

  color: #ea4335;

}

.btn-danger.btn-bordered:hover,

.btn-danger.btn-bordered:active,

.btn-danger.btn-bordered:focus {

  border-color: #ff3838;

  color: #ffffff;

}

.btn-violet {

  background: #7045ff;

  color: #ffffff;

}

.btn-violet:hover,

.btn-violet:active,

.btn-violet:focus {

  color: #ffffff;

  background: #8445ff !important;

}

.btn-violet.btn-bordered {

  border-color: #7045ff;

  color: #7045ff;

}

.btn-violet.btn-bordered:hover,

.btn-violet.btn-bordered:active,

.btn-violet.btn-bordered:focus {

  border-color: #8445ff;

  color: #ffffff;

}

.btn-orange {

  background: #ff460b;

  color: #ffffff;

}

.btn-orange:hover,

.btn-orange:active,

.btn-orange:focus {

  color: #ffffff;

  background: #ff6230 !important;

}

.btn-orange.btn-bordered {

  border-color: #ff460b;

  color: #ff460b;

}

.btn-orange.btn-bordered:hover,

.btn-orange.btn-bordered:active,

.btn-orange.btn-bordered:focus {

  border-color: #ff6230;

  color: #ffffff;

}

.tour-buttons .btn.btn-sm {

  height: 30px;

  font-size: 12px;

  line-height: 30px;

  padding: 0 10px;

}

@media (max-width: 999px) {

  .list-inline-faq li {

    width: 100%;

  }

}

.limit-width {

  max-width: 600px;

  display: block;

  margin: 0px auto 0px;

  float: none;

}

.width-100 {

  max-width: 100px !important;

  width: 100px !important;

}

.width-200 {

  max-width: 200px !important;

  width: 200px !important;

}

.width-300 {

  max-width: 300px !important;

  width: 300px !important;

}

.width-400 {

  max-width: 400px !important;

  width: 400px !important;

}

.width-500 {

  max-width: 500px !important;

  width: 500px !important;

}

/* Background colors */

.bg-custom {

  background-color: #71b6f9 !important;

}

.bg-primary {

  background-color: #1d84df !important;

}

.bg-success {

  background-color: #00bf4f !important;

}

.bg-info {

  background-color: #00aeff !important;

}

.bg-warning {

  background-color: #ff8f00 !important;

}

.bg-danger {

  background-color: #ea4335 !important;

}

.bg-muted {

  background-color: #f4f8fb !important;

}

.bg-inverse {

  background-color: #212121 !important;

}

.bg-purple {

  background-color: #5b69bc !important;

}

.bg-pink {

  background-color: #ff8acc !important;

}

.bg-white {

  background-color: #ffffff !important;

}

.bg-lightdark {

  background-color: #f4f8fb !important;

}

.bg-violet {

  background-color: #7045ff !important;

}

.bg-orange {

  background-color: #ff460b !important;

}

/* Text colors */

.text-custom {

  color: #6200ea !important;

}

.text-white {

  color: #ffffff !important;

}

.text-danger {

  color: #ff1744 !important;

}

.text-muted {

  color: #aab2bd !important;

}

.text-primary {

  color: #188ae2 !important;

}

.text-warning {

  color: #fbbc05 !important;

}

.text-success {

  color: #00bf4f !important;

}

.text-info {

  color: #00aeff !important;

}

.text-inverse {

  color: #212121 !important;

}

.text-pink {

  color: #ff8acc !important;

}

.text-purple {

  color: #5b69bc !important;

}

.text-dark {

  color: #212121 !important;

}

.text-orange {

  color: #ff460b !important;

}

.table > tbody > tr.success > td,

.table > tbody > tr.success > th,

.table > tbody > tr > td.success,

.table > tbody > tr > th.success,

.table > tfoot > tr.success > td,

.table > tfoot > tr.success > th,

.table > tfoot > tr > td.success,

.table > tfoot > tr > th.success,

.table > thead > tr.success > td,

.table > thead > tr.success > th,

.table > thead > tr > td.success,

.table > thead > tr > th.success {

  background: #00bf4f;

}

.table > tbody > tr.info > td,

.table > tbody > tr.info > th,

.table > tbody > tr > td.info,

.table > tbody > tr > th.info,

.table > tfoot > tr.info > td,

.table > tfoot > tr.info > th,

.table > tfoot > tr > td.info,

.table > tfoot > tr > th.info,

.table > thead > tr.info > td,

.table > thead > tr.info > th,

.table > thead > tr > td.info,

.table > thead > tr > th.info {

  background: #00aeff;

}

.table > tbody > tr.primary > td,

.table > tbody > tr.primary > th,

.table > tbody > tr > td.primary,

.table > tbody > tr > th.primary,

.table > tfoot > tr.primary > td,

.table > tfoot > tr.primary > th,

.table > tfoot > tr > td.primary,

.table > tfoot > tr > th.primary,

.table > thead > tr.primary > td,

.table > thead > tr.primary > th,

.table > thead > tr > td.primary,

.table > thead > tr > th.primary {

  background: #1d84df;

}

.table > tbody > tr.danger > td,

.table > tbody > tr.danger > th,

.table > tbody > tr > td.danger,

.table > tbody > tr > th.danger,

.table > tfoot > tr.danger > td,

.table > tfoot > tr.danger > th,

.table > tfoot > tr > td.danger,

.table > tfoot > tr > th.danger,

.table > thead > tr.danger > td,

.table > thead > tr.danger > th,

.table > thead > tr > td.danger,

.table > thead > tr > th.danger {

  background: #ea4335;

}

.table > tbody > tr.warning > td,

.table > tbody > tr.warning > th,

.table > tbody > tr > td.warning,

.table > tbody > tr > th.warning,

.table > tfoot > tr.warning > td,

.table > tfoot > tr.warning > th,

.table > tfoot > tr > td.warning,

.table > tfoot > tr > th.warning,

.table > thead > tr.warning > td,

.table > thead > tr.warning > th,

.table > thead > tr > td.warning,

.table > thead > tr > th.warning {

  background: #fbbc05;

}

/*---------------------------------------------------------------------*/

/* Header */

/*

Themes: My Admin

Version 1.0

[Table of contents]

-------------------------------------------------------------------------

1. Main Menu

2. Structure

3. Header

4. User Main Menu

5. Navigation

6. Fixed Navbar

-------------------------------------------------------------------------*/

/*-----------------------------------------------------------------------

1. Main Menu

*/

.my_logo{
  display: block;
  text-align: center;
  margin: 40px 0;
}


.main-menu {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 30;
  width: 260px;
  height: 100%;
  padding-top: 0px;
  background: #ffffff;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 1px 1px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 1px 1px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 1px 1px rgba(0, 0, 0, 0.05);
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;

  -webkit-transition: all 0.4s ease;

  /* background-image: linear-gradient(to bottom, rgba(238, 125, 50, 0), #ff5f49), url('../images/side_bar.png'); */
}

.main-menu .content {

  height: 100%;

  overflow: auto;

}

.main-menu .button-close {

  display: none;

  position: absolute;

  top: 25px;

  right: 10px;

  font-size: 16px;

  outline: none;

  background: none;

  border: none;

  font-size: 20px;

  color: #ffffff;

}

.main-menu .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {

  background: #aab2bd;

}

.main-menu:hover .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {

  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";

  -moz-opacity: 1;

  -khtml-opacity: 1;

  opacity: 1;

}

.main-content {

  position: relative;

  z-index: 10;

  margin-left: 280px;

  margin-right: 20px;

  padding-top: 75px;

}

/*---------------------------------------------------------------------*/

/*-----------------------------------------------------------------------

2. Structure

*/

.fixed-navbar {

  position: absolute;

  top: 0px;

  left: 260px;

  z-index: 20;

  right: 0px;

  padding: 0px 20px 0px 80px;

  background: transparent;

  color: #ffffff;

}

.fixed-navbar .searchform {

  opacity: 0;

  visibility: hidden;

  position: absolute;

  right: -3px;

  top: 13px;

  height: 49px;

  font-size: 0;

  line-height: 0;

  padding: 0;

  width: 50px;

  transition: all 0.4s ease;

  -moz-transition: all 0.4s ease;

  -o-transition: all 0.4s ease;

  -ms-transition: all 0.4s ease;

  -webkit-transition: all 0.4s ease;

}

.fixed-navbar .searchform.active {

  opacity: 1;

  width: 320px;

  visibility: visible;

  transform: translateY(0);

  -moz-transform: translateY(0);

  -webkit-transform: translateY(0);

}

.fixed-navbar .searchform .input-search {

  font-weight: 500;

  height: 49px;

  font-size: 14px;

  width: 320px;

  max-width: 100%;

  border-radius: 25px;

  -webkit-border-radius: 25px;

  -moz-border-radius: 25px;

}

.fixed-navbar .searchform .button-search {

  top: 50%;

  right: 11px;

  color: #aab2bd;

  font-size: 18px;

  transform: translate(0, -50%);

  -moz-transform: translate(0, -50%);

  -webkit-transform: translate(0, -50%);

}

.fixed-navbar .searchform .button-search.mdi {

  font-size: 24px;

}

@media (max-width: 799px) {

  .main-menu {

    transform: translate(-110%, 0);

    -webkit-transform: translate(-110%, 0);

    -moz-transform: translate(-110%, 0);

    -o-transform: translate(-110%, 0);

    -ms-transform: translate(-110%, 0);

  }

  .main-menu .header {

    text-align: left;

    padding-left: 20px;

  }

  .main-menu .button-close {

    display: block;

  }

  .main-content {

    margin-left: 20px;

  }

  .fixed-navbar {

    left: 0px;

    right: 0px;

    box-shadow: 0 0px 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);

    -moz-box-shadow: 0 0px 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);

    -webkit-box-shadow: 0 0px 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);

  }

  .menu-active .main-menu {

    transform: translate(0, 0);

    -webkit-transform: translate(0, 0);

    -moz-transform: translate(0, 0);

    -o-transform: translate(0, 0);

    -ms-transform: translate(0, 0);

  }

}

@media (max-width: 599px) {

  .fixed-navbar .searchform {

    top: 75px;

    position: fixed;

    width: 100%;

    right: 0;

    box-shadow: 0 0px 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);

    -moz-box-shadow: 0 0px 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);

    -webkit-box-shadow: 0 0px 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);

  }

  .fixed-navbar .searchform.active {

    width: 100%;

  }

  .fixed-navbar .searchform .input-search {

    width: 100%;

    border-radius: 0;

    -moz-border-radius: 0;

    -webkit-border-radius: 0;

  }

}

/*---------------------------------------------------------------------*/

/*-----------------------------------------------------------------------

3. Header

*/

.header {

  position: absolute;

  top: 0px;

  left: 0px;

  width: 100%;

  height: 185px;

  text-align: left;

  padding-left: 20px;

  padding-right: 20px;

  padding-top: 75px;

}

.header .logo {

  position: absolute;

  top: 0;

  left: 0;

  width: 100%;

  text-align: center;

  font-family: "Poppins", sans-serif;

  font-size: 20px;

  line-height: 75px;

  height: 75px;

  white-space: nowrap;

  overflow: hidden;

  color: #1d84df;

  background: #fff;

  font-weight: 500;

}

.header .logo .ico {

  margin-right: 5px;

  font-size: 24px;

  position: relative;

  top: 2px;

}

/*---------------------------------------------------------------------*/

/*-----------------------------------------------------------------------

4. User Main Menu

*/

.user {

  position: absolute;

  top: 75px;

  left: 0;

  width: 100%;

  padding: 32px 40px 0 100px;

  height: 110px;

}

.user:after {

  content: "";

  display: block;

  width: 0px;

  height: 0px;

  clear: both;

  overflow: hidden;

}

.user .control-wrap.active .control-list {

  opacity: 1;

  visibility: visible;

}



.control-list a svg{

  vertical-align: middle;

  margin: 0 10px 0 0;;

}

.user .avatar {

  max-width: 70px;

  position: absolute;

  top: 19px;

  left: 20px;

}

.user .avatar img {

  border: 3px solid #d7ecfb;

  -webkit-border-radius: 100%;

  -moz-border-radius: 100%;

  border-radius: 100%;

  transition: all 0.4s ease;

  -moz-transition: all 0.4s ease;

  -o-transition: all 0.4s ease;

  -ms-transition: all 0.4s ease;

  -webkit-transition: all 0.4s ease;

}

.user .control-wrap {

  position: absolute;

  width: 20px;

  height: 40px;

  right: 15px;

  top: 44px;

  text-align: center;

}

.user .control-wrap > .fa {

  font-size: 18px;

  line-height: 20px;

  color: #434a54;

  cursor: pointer;

}

.user .control-list {

  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";

  -moz-opacity: 0;

  -khtml-opacity: 0;

  opacity: 0;

  visibility: hidden;

  position: absolute;

  top: 5px;

  right: 0;

  margin-right: 5px;

  z-index: 20;

  width: 230px;

  margin-top: 2px;

  padding: 20px 0px;

  border: 1px solid rgba(152, 166, 173, 0.15);

  background: #ffffff;

  list-style: none;

  transition: all 0.4s ease;

  -moz-transition: all 0.4s ease;

  -o-transition: all 0.4s ease;

  -ms-transition: all 0.4s ease;

  -webkit-transition: all 0.4s ease;

  -webkit-border-radius: 3px;

  -moz-border-radius: 3px;

  border-radius: 3px;

  transform: translate(0, 20px);

  -webkit-transform: translate(0, 20px);

  -moz-transform: translate(0, 20px);

  -o-transform: translate(0, 20px);

  -ms-transform: translate(0, 20px);

  box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);

  -moz-box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);

  -webkit-box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);

}





.user .control-list::before {

  position: absolute;

  content: '';

  width: 15px;

  height: 15px;

  border-left: 1px solid #eeece5;

  border-top: 1px solid #eeece5;

  top: 0;

  right: 5.5%;

  transform: rotate(45deg);

  margin-top: -8px;

  background: #fff;

}





.user .control-list a {

  top: 0px;

  color: #435966;

  font-size: 14px;

  line-height: 24px;

  padding: 15px 10px;

  display: block;

  text-align: left;

  margin: 0 0 15px;

}

.user .control-list a i {

  font-size: 16px;

  line-height: 24px;

  width: 25px;

}

.user .control-list a:hover {

  color: #1d84df;

}

.user .control-list a:hover {

  background: #e5f7ff;

  color: #fff;

}

.user .name {

  color: #435966;

  margin: 0px;

  font-size: 22px;

  line-height: 26px;

  font-weight: 500;

  overflow: hidden;

  text-overflow: ellipsis;

  white-space: nowrap;

}

.user .name a {

  color: #435966;

  font-family: 'BurbankBigCondensedBold';

}

@media (min-width: 1025px) {

  .user .name a:hover {

    color: #1d84df;

  }

}

.user .position {

  color: #aab2bd;

  font-weight: 400;

  font-size: 15px;

  line-height: 15px;

  margin: 0;

}

.user .status {

  position: absolute;

  bottom: 5px;

  right: 5px;

  width: 12px;

  height: 12px;

  border: 1px solid #ffffff;

  background: #ffffff;

  -webkit-border-radius: 100%;

  -moz-border-radius: 100%;

  border-radius: 100%;

}

.user .status:before {

  content: "";

  position: absolute;

  top: 1px;

  left: 1px;

  width: 8px;

  height: 8px;

  background: #00bf4f;

  -webkit-border-radius: 100%;

  -moz-border-radius: 100%;

  border-radius: 100%;

}





/*switch users settings*/

.control-list .control-item img{

  width: 35px;

  height: 35px;

  border-radius: 50%;

  float: left;

  margin: -5px 15px 0 0;

}

.control-list .control-item p{

  font-size: 14px;

  font-family: 'Poppins', sans-serif;

  font-style: normal;

  font-stretch: condensed;

  line-height: normal;

  letter-spacing: normal;

  color: #424247;

  margin: 6px 0 0 0;

}

.control-list .control-item p span{

  display: block;

  font-family: 'BurbankBigRegularMedium';

}

.control-list .control-item p span abbr{

  float: right;

  color: #cdcdd2;

}



/*---------------------------------------------------------------------*/

/*-----------------------------------------------------------------------

5. Navigation

*/

.navigation{

  margin: 40px 0 0 0;

}



.navigation ul li a svg{

  vertical-align: middle;

}

.navigation .title {

  padding: 12px 20px;

  margin: 0;

  font-size: 16px;

  line-height: 26px;

  font-weight: 500;

  color: #212121;

  background: #e6e9ed;

}

.navigation .menu {

  list-style: none;

  padding: 0px;

  margin: 0px;

}

.navigation .menu a {

  display: block;

  color: #434a54;

}

@media (min-width: 1025px) {

  .navigation .menu a:hover {

    color: #fff;

  }

  .navigation .menu a:hover i {

    color: #fff;

  }

}

.navigation .menu a.active,

.navigation .menu a.current {

  color: #1d84df;

}

.navigation .menu a i {

  color: #212121;

  transition: all 0.4s ease;

  -moz-transition: all 0.4s ease;

  -o-transition: all 0.4s ease;

  -ms-transition: all 0.4s ease;

  -webkit-transition: all 0.4s ease;

}

.navigation .menu > li {

  transition: all 0.4s ease;

  -moz-transition: all 0.4s ease;

  -o-transition: all 0.4s ease;

  -ms-transition: all 0.4s ease;

  -webkit-transition: all 0.4s ease;

}

.navigation .menu > li > a {

  position: relative;

  padding: 14px 30px 14px 53px;

  margin: 0px;

  font-size: 22px;

  font-family: 'Poppins', sans-serif;
  
  color: #9c9fc5;

}



.navigation .menu > li > a i{
  color: #9c9fc5;
  margin: 0 10px 0 0;
  vertical-align: middle;

}







.navigation .menu > li > a span {

  vertical-align: middle;

  font-size: 17px;

}

.navigation .menu > li > a .menu-arrow {

  position: absolute;

  top: 14px;

  right: 20px;

  font-size: 14px;

  line-height: 18px;

  transition: all 0.4s ease;

  -moz-transition: all 0.4s ease;

  -o-transition: all 0.4s ease;

  -ms-transition: all 0.4s ease;

  -webkit-transition: all 0.4s ease;

}

.navigation .menu > li.active {

  background: rgba(0, 0, 0, 0.05);

}

.navigation .menu > li.active > a {

  background: #e6e9ed;

}

.navigation .menu > li.active > a .menu-arrow {

  transform: rotate(-180deg);

  -webkit-transform: rotate(-180deg);

  -moz-transform: rotate(-180deg);

  -o-transform: rotate(-180deg);

  -ms-transform: rotate(-180deg);

}

.navigation .menu > li.current > a {

  background: #1d84df;

  color: #ffffff;

}

.navigation .menu > li.current > a > i {

  color: #ffffff;

}

.navigation .menu > li.current .current > a {

  color: #1d84df;

}

.navigation .menu > li.current .sub-menu {

  display: block;

}

.navigation .menu .sub-menu {

  display: none;

  list-style: none;

  padding: 0px;

  margin: 0px;

}

.navigation .menu .sub-menu a {

  padding: 10px 20px 10px 53px;

}

.navigation .menu .menu-icon {

  display: inline-block;

  position: absolute;

  top: 0px;

  left: 0;

  margin-left: 0;

  width: 50px;

  font-size: 18px;

  line-height: 49px;

  text-align: center;

  vertical-align: middle;

}

.navigation .menu .menu-icon.mdi {

  font-size: 20px;

  margin: 7px 0 0 0;

}

.navigation .menu .notice {

  position: absolute;

  top: 12px;

  right: 20px;

  font-size: 12px;

}

.navigation .menu .waves-ripple {

  background: -webkit-radial-gradient(rgba(113, 182, 249, 0.2) 0, rgba(113, 182, 249, 0.3) 40%, rgba(113, 182, 249, 0.4) 50%, rgba(113, 182, 249, 0.5) 60%, rgba(113, 182, 249, 0) 70%);

  background: -o-radial-gradient(rgba(113, 182, 249, 0.2) 0, rgba(113, 182, 249, 0.3) 40%, rgba(113, 182, 249, 0.4) 50%, rgba(113, 182, 249, 0.5) 60%, rgba(113, 182, 249, 0) 70%);

  background: -moz-radial-gradient(rgba(113, 182, 249, 0.2) 0, rgba(113, 182, 249, 0.3) 40%, rgba(113, 182, 249, 0.4) 50%, rgba(113, 182, 249, 0.5) 60%, rgba(113, 182, 249, 0) 70%);

  background: radial-gradient(rgba(113, 182, 249, 0.2) 0, rgba(113, 182, 249, 0.3) 40%, rgba(113, 182, 249, 0.4) 50%, rgba(113, 182, 249, 0.5) 60%, rgba(113, 182, 249, 0) 70%);

}

@media (min-width: 800px) {

  .main-menu {

    transition: none;

    -moz-transition: none;

    -o-transition: none;

    -ms-transition: none;

    -webkit-transition: none;

  }

  .header .logo {

    transition: none;

    -moz-transition: none;

    -o-transition: none;

    -ms-transition: none;

    -webkit-transition: none;

  }

  .menu-active .header {

    width: 75px;

    height: 0;

    padding: 0;

  }

  .menu-active .header .logo {

    /*position: fixed;

    z-index: 988;

    width: 75px;

    height: 75px;

    font-size: 0;

    line-height: 80px;

    color: #1d84df;*/

    /* background: url('assets/images/gamepad.png'); */

  }

  .menu-active .header .logo::first-letter {

    font-size: 28px;

  }

  .menu-active .header .logo:before {

    /*content: '';

    position: absolute;

    left: 0;

    top: 0;

    width: 75px;

    height: 75px;

    background: #1d84df;

    z-index: -2;*/

    /*background: url('assets/images/gamepad.png');*/

  }

  .icon-before-logo::before{

    /* background: url('assets/images/gamepad.png'); */

    height: 50px;

    width: 100px;

  }

  


  .menu-active .header .logo .ico {

    margin-right: 0;

    top: 0;

  }

  .menu-active .main-menu {

    position: absolute;

    width: 75px;

    padding-top: 75px;

  }

  .menu-active .fixed-navbar {

    left: 75px;

    z-index: 40;

  }

  .menu-active .main-content {

    margin-left: 95px;

  }

  .menu-active .user {

    display: none;

  }

  .menu-active .navigation .title {

    display: none;

  }

  .menu-active .navigation .menu > li {

    position: relative;

  }

  .menu-active .navigation .menu > li > a {

    height: 48px;

    padding: 10px 30px;

    transition: color 0.4s ease;

    -moz-transition: color 0.4s ease;

    -o-transition: color 0.4s ease;

    -ms-transition: color 0.4s ease;

    -webkit-transition: color 0.4s ease;

  }

  .menu-active .navigation .menu > li > a span {

    display: none;

    font-size: 14px;

    line-height: 30px;

  }

  .menu-active .navigation .menu > li > a .menu-arrow,

  .menu-active .navigation .menu > li > a .notice {

    display: none;

  }

  .menu-active .navigation .menu > li:hover > a {

    /*width: 260px;*/

    background: #1d84df;

    color: #ffffff;

    /*padding-left: 90px;*/

  }

  .menu-active .navigation .menu > li:hover > a i {

    color: #ffffff;

  }

  .menu-active .navigation .menu > li:hover > a span {

    display: none;

  }

  .menu-active .navigation .menu > li:hover > a .notice {

    display: none;

  }

  .menu-active .navigation .menu > li:hover .sub-menu {

    display: block!important;

  }

  .menu-active .navigation .menu .menu-icon {

    font-size: 24px;

    width: 75px;

  }

  .menu-active .navigation .menu .menu-icon.mdi {

    font-size: 26px;

  }

  .menu-active .navigation .menu .sub-menu {

    display: none!important;

    position: absolute;

    top: 100%;

    left: 75px;

    width: 185px;

  }

  .menu-active .navigation .menu .sub-menu a {

    background: #e6e9ed;

    padding: 10px 15px;

  }

  .menu-active .mCSB_container,

  .menu-active .mCustomScrollBox,

  .menu-active .content {

    overflow: visible;

  }

  .menu-active body {

    height: auto;

  }

}

/*---------------------------------------------------------------------*/

/*-----------------------------------------------------------------------

6. Fixed Navbar

*/

.fixed-navbar {

  height: 75px;

}

.fixed-navbar .ico-item {

  display: inline-block;

  position: relative;

  margin-left: 15px;

  cursor: pointer;

  font-size: 19px;

  line-height: 75px;

  color: #1d84df;

}

@media (min-width: 1025px) {

  .fixed-navbar .ico-item:hover {

    color: #ffffff;

  }

}

.fixed-navbar .ico-item .ico-img {

  max-height: 40px;

  width: auto;

  margin-top: -3px;

  border-radius: 100%;

  -webkit-border-radius: 100%;

  -moz-border-radius: 100%;

}

.fixed-navbar .ico-item .ico-item {

  margin-left: 0;

}

.fixed-navbar .ico-item .ico-item.mdi {

  font-size: 24px;

}

.fixed-navbar .ico-item .ico-item.mdi:before {

  font-size: 24px;

  line-height: 75px;

  position: relative;

  top: 3px;

}

.fixed-navbar .ico-item.mdi {

  font-size: 24px;

}

.fixed-navbar .ico-item.mdi:before {

  font-size: 24px;

  line-height: 75px;

  position: relative;

  top: 3px;

}

.fixed-navbar .ico-item.pulse:before {

  content: '';

  width: 2px;

  height: 2px;

  border: 3px solid #ffce54 ;

  -webkit-border-radius: 30px;

  -moz-border-radius: 30px;

  border-radius: 30px;

  background-color: #ffce54 ;

  z-index: 10;

  position: absolute;

  right: 2px;

  top: 28px;

}

.fixed-navbar .ico-item.pulse:after {

  content: '';

  border: 4px solid #ffce54 ;

  background: transparent;

  -webkit-border-radius: 60px;

  -moz-border-radius: 60px;

  border-radius: 60px;

  height: 30px;

  width: 30px;

  -webkit-animation: pulse 3s ease-out;

  -moz-animation: pulse 3s ease-out;

  animation: pulse 3s ease-out;

  -webkit-animation-iteration-count: infinite;

  -moz-animation-iteration-count: infinite;

  animation-iteration-count: infinite;

  position: absolute;

  top: 16px;

  left: -1px;

  z-index: 1;

  opacity: 0;

}

.fixed-navbar .ico-item.toggle-hover {

  position: relative;

}

.fixed-navbar .ico-item.toggle-hover .toggle-content {

  opacity: 0;

  visibility: hidden;

  position: absolute;

  top: 100%;

  right: -10px;

  background: #ffffff;

  border: 1px solid rgba(152, 166, 173, 0.15);

  transition: all 0.4s ease;

  -moz-transition: all 0.4s ease;

  -o-transition: all 0.4s ease;

  -ms-transition: all 0.4s ease;

  -webkit-transition: all 0.4s ease;

  -webkit-border-radius: 3px;

  -moz-border-radius: 3px;

  border-radius: 3px;

  transform: translate(0, 20px);

  -webkit-transform: translate(0, 20px);

  -moz-transform: translate(0, 20px);

  -o-transform: translate(0, 20px);

  -ms-transform: translate(0, 20px);

  box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);

  -moz-box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);

  -webkit-box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);

}

.fixed-navbar .ico-item.toggle-hover .toggle-content ul {

  margin: 0;

  padding: 10px 15px 0;

  list-style: none;

  font-size: 0;

  line-height: 0;

  text-align: left;

  width: 300px;

}

.fixed-navbar .ico-item.toggle-hover .toggle-content ul li {

  width: calc(33.33333333%);

  display: inline-block;

  text-align: center;

  margin-bottom: 10px;

}

.fixed-navbar .ico-item.toggle-hover .toggle-content ul a {

  display: block;

  font-size: 14px;

  line-height: 24px;

  color: #435966;

}

.fixed-navbar .ico-item.toggle-hover .toggle-content ul a > i {

  display: block;

  font-size: 18px;

  line-height: 28px;

}

.fixed-navbar .ico-item.toggle-hover .toggle-content .read-more {

  display: block;

  background: #f5f5f5;

  color: #435966;

  font-size: 14px;

  line-height: 34px;

  text-align: center;

}

.fixed-navbar .ico-item.toggle-hover .toggle-content .read-more:hover {

  color: #1d84df;

}

.fixed-navbar .ico-item.toggle-hover.active .toggle-content {

  opacity: 1;

  visibility: visible;

  transform: translate(0);

  -webkit-transform: translate(0);

  -moz-transform: translate(0);

}

.fixed-navbar .ico-item:hover .sub-ico-item {

  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";

  -moz-opacity: 1;

  -khtml-opacity: 1;

  opacity: 1;

  visibility: visible;

  transform: translate(0, 0);

  -webkit-transform: translate(0, 0);

  -moz-transform: translate(0, 0);

  -o-transform: translate(0, 0);

  -ms-transform: translate(0, 0);

}

.fixed-navbar .sub-ico-item {

  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";

  -moz-opacity: 0;

  -khtml-opacity: 0;

  opacity: 0;

  visibility: hidden;

  position: absolute;

  right: 0;

  top: 48px;

  background-color: #ffffff;

  padding: 5px 0;

  font-size: 0;

  line-height: 0;

  list-style: none;

  border: 1px solid rgba(152, 166, 173, 0.15);

  transition: all .4s ease;

  -moz-transition: all .4s ease;

  -o-transition: all .4s ease;

  -ms-transition: all .4s ease;

  -webkit-transition: all .4s ease;

  -webkit-border-radius: 3px;

  -moz-border-radius: 3px;

  border-radius: 3px;

  transform: translate(0, 20px);

  -webkit-transform: translate(0, 20px);

  -moz-transform: translate(0, 20px);

  -o-transform: translate(0, 20px);

  -ms-transform: translate(0, 20px);

  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);

  -moz-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);

  -webkit-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);

}

.fixed-navbar .sub-ico-item:before {

  content: '';

  position: absolute;

  height: 10px;

  width: 100%;

  top: -10px;

  left: 0;

}

.fixed-navbar .sub-ico-item a {

  display: block;

  width: 200px;

  padding: 6px 20px 6px 20px;

  color: #435966;

  font-size: 14px;

  line-height: 24px;

}

.fixed-navbar .sub-ico-item a:hover {

  color: #1d84df;

}

@media (max-width: 479px) {

  .fixed-navbar .ico-item {

    display: none;

  }

  .fixed-navbar .ico-item:first-child {

    display: inline-block;

  }

}

/*---------------------------------------------------------------------*/

/* Style */

/*

Themes: My Admin

Version 1.0

[Table of contents]

-------------------------------------------------------------------------

1. Footer

2. Box Content

3. Calendar

4. Chartist Chart

5. Checkbox

6. Data Tables

7. Dropcap

8. Example Content

9. Inbox List

10. Jquery UI

11. Menu Mobile Button

12. Morris Chart

13. Notice

14. noUiSlider

15. Process Bar

16. Radio

17. Search Form

18. Statistics Box

19. Switch

20. Tabs

21. Timepicke

22. Title

23. User Info

24. Widget Stat

25. Widget Stat Chart

26. Misc

27. Mailbox

28. Login, Register, ... Form

29. 404,500 Page

30. Projects Page

31. Task Board

32. Profile

33. Contact List

34. Pricing Plan

35. Invoice

36. Gallery

37. Modal

38. Form Control

39. Reviews Widget

40. Activity Widget

41. Todo Widget

42. X-Editable

43. Sparkline Chart

44. SweetAlert

-------------------------------------------------------------------------*/

/*-----------------------------------------------------------------------

1. Footer

*/

.footer {

  padding: 19px 30px 20px 30px;

  margin: 0px -20px 0px -20px;

  color: rgba(0, 0, 0, 0.7);

}

.footer a {

  color: rgba(0, 0, 0, 0.7);

}

@media (min-width: 1025px) {

  .footer a:hover {

    color: #1d84df;

  }

}

/*---------------------------------------------------------------------*/

/*-----------------------------------------------------------------------

2. Box Content

*/

.box-content {

  position: relative;

  padding: 20px;

  margin: 0px 0px 20px 0px;

  background: #ffffff;

  -webkit-border-radius: 3px;

  -moz-border-radius: 3px;

  border-radius: 3px;

  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 1px 1px rgba(0, 0, 0, 0.05);

  -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 1px 1px rgba(0, 0, 0, 0.05);

  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 1px 1px rgba(0, 0, 0, 0.05);

  /* State */

  /* Style */

  /* Color */

}

.box-content:after {

  content: "";

  display: block;

  width: 0px;

  height: 0px;

  clear: both;

  overflow: hidden;

}

.box-content .box-title {

  margin: 0px 0px 30px 0px;

  font-size: 16px;

  line-height: 16px;

  font-weight: 600;

  transition: all 0.4s ease;

  -moz-transition: all 0.4s ease;

  -o-transition: all 0.4s ease;

  -ms-transition: all 0.4s ease;

  -webkit-transition: all 0.4s ease;

}

.box-content .box-title .ico {

  margin-right: 8px;

  line-height: 16px;

}

.box-content .box-title.with-control .controls {

  position: absolute;

  right: 20px;

  top: 20px;

  font-size: 0px;

  line-height: 16px;

}

.box-content .box-title.with-control .control {

  border: none;

  background: none;

  outline: none;

  height: 16px;

  font-size: 14px;

}

.box-content .box-title.with-control .fa-times {

  position: relative;

  top: -1px;

}

.box-content .content:after {

  content: "";

  display: block;

  width: 0px;

  height: 0px;

  clear: both;

  overflow: hidden;

}

.box-content .dropdown.js__drop_down {

  position: absolute;

  right: 20px;

  top: 20px;

}

.box-content .dropdown.js__drop_down .dropdown-icon {

  color: #aab2bd;

}

@media (min-width: 1025px) {

  .box-content .dropdown.js__drop_down .dropdown-icon:hover {

    color: #435966;

  }

}

.box-content .dropdown.js__drop_down .sub-menu {

  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";

  -moz-opacity: 0;

  -khtml-opacity: 0;

  opacity: 0;

  visibility: hidden;

  position: absolute;

  top: 0px;

  right: 100%;

  margin-right: 5px;

  z-index: 20;

  width: 200px;

  margin-top: 2px;

  padding: 10px 0px;

  border: 1px solid rgba(152, 166, 173, 0.15);

  background: #ffffff;

  list-style: none;

  transition: all 0.4s ease;

  -moz-transition: all 0.4s ease;

  -o-transition: all 0.4s ease;

  -ms-transition: all 0.4s ease;

  -webkit-transition: all 0.4s ease;

  -webkit-border-radius: 3px;

  -moz-border-radius: 3px;

  border-radius: 3px;

  transform: translate(0, 20px);

  -webkit-transform: translate(0, 20px);

  -moz-transform: translate(0, 20px);

  -o-transform: translate(0, 20px);

  -ms-transform: translate(0, 20px);

  box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);

  -moz-box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);

  -webkit-box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);

}

.box-content .dropdown.js__drop_down .sub-menu a {

  display: block;

  padding: 6px 20px 6px 20px;

  color: #435966;

}

@media (min-width: 1025px) {

  .box-content .dropdown.js__drop_down .sub-menu a:hover {

    color: #1d84df;

  }

}

.box-content .dropdown.js__drop_down .sub-menu .split {

  margin: 9px 0px 9px 0px;

  width: 100%;

  height: 1px;

  background: #e5e5e5;

}

.box-content .dropdown.js__drop_down.active .dropdown-icon {

  color: #435966;

}

.box-content .dropdown.js__drop_down.active .sub-menu {

  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";

  -moz-opacity: 1;

  -khtml-opacity: 1;

  opacity: 1;

  visibility: visible;

  transform: translate(0, 0);

  -webkit-transform: translate(0, 0);

  -moz-transform: translate(0, 0);

  -o-transform: translate(0, 0);

  -ms-transform: translate(0, 0);

}

.box-content.card-closed .box-title {

  margin-bottom: 0px !important;

}

.box-content.card-closed .box-title.with-control .fa-minus:before {

  content: "\f067";

}

.box-content.bordered {

  border-top: 4px solid #435966;

  -webkit-border-radius: 0px;

  -moz-border-radius: 0px;

  border-radius: 0px;

}

.box-content.bordered-all {

  border: 1px solid #435966;

  border-top: 4px solid #435966;

  -webkit-border-radius: 0px;

  -moz-border-radius: 0px;

  border-radius: 0px;

}

.box-content.card {

  padding: 0px;

  -webkit-border-radius: 0px;

  -moz-border-radius: 0px;

  border-radius: 0px;

}

.box-content.card .box-title {

  margin-bottom: 0px;

  padding: 10px 20px 10px 20px;

  line-height: 30px;

  color: #ffffff !important;

  background-color: #435966;

}

.box-content.card .box-title .ico {

  line-height: 30px;

}

.box-content.card .box-title .controls {

  top: 17px;

}

.box-content.card .dropdown {

  top: 15px;

}

.box-content.card .dropdown .dropdown-icon {

  color: #ffffff;

}

.box-content.card .card-content {

  padding: 20px;

}

.box-content.card.bordered-all {

  border-top: none;

}

.box-content.card .dropdown.js__drop_down .dropdown-icon {

  color: #ffffff !important;

}

.box-content.card.white .box-title {

  color: #435966 !important;

  background: #ffffff;

  border-bottom: 1px solid #e6e9ed;

}

.box-content.primary {

  border-color: #188ae2;

}

.box-content.primary .box-title {

  color: #188ae2;

}

.box-content.success {

  border-color: #00bf4f;

}

.box-content.success .box-title {

  color: #00bf4f;

}

.box-content.info {

  border-color: #00aeff;

}

.box-content.info .box-title {

  color: #00aeff;

}

.box-content.warning {

  border-color: #fbbc05;

}

.box-content.warning .box-title {

  color: #fbbc05;

}

.box-content.danger {

  border-color: #ea4335;

}

.box-content.danger .box-title {

  color: #ea4335;

}

.box-content.muted {

  border-color: #f4f8fb;

}

.box-content.muted .box-title {

  color: #f4f8fb;

}

.box-content.inverse {

  border-color: #212121;

}

.box-content.inverse .box-title {

  color: #212121;

}

.box-content.purple {

  border-color: #5b69bc;

}

.box-content.purple .box-title {

  color: #5b69bc;

}

.box-content.pink {

  border-color: #ff8acc;

}

.box-content.pink .box-title {

  color: #ff8acc;

}

.box-content.lightdark {

  border-color: #f4f8fb;

}

.box-content.lightdark .box-title {

  color: #f4f8fb;

}

/*---------------------------------------------------------------------*/

/*-----------------------------------------------------------------------

3. Calendar

*/

.calendar {

  float: left;

  margin-bottom: 0px;

}

.fc-view {

  margin-top: 30px;

}

.none-border .modal-footer {

  border-top: none;

}

.fc-toolbar {

  margin-bottom: 5px;

  margin-top: 15px;

}

.fc-toolbar h2 {

  font-size: 18px;

  line-height: 30px;

  font-weight: 600;

  text-transform: uppercase;

}

.fc-toolbar .fc-state-active,

.fc-toolbar .ui-state-active,

.fc-toolbar button:focus,

.fc-toolbar button:hover {

  z-index: 0;

}

.fc-widget-header,

.fc-widget-content {

  border: 1px solid #d7ecfb;

}

.fc th.fc-widget-header {

  background: #f5f5f5;

  font-size: 14px;

  line-height: 20px;

  padding: 10px 0px 10px 0px;

  text-transform: uppercase;

}

.fc th.fc-widget-header a {

  color: #505458;

}

.fc .fc-event .fc-bg {

  display: none;

}

.fc .fc-list-item[class*=" bg-"] {

  color: #ffffff;

}

.fc .fc-list-item[class*=" bg-"]:hover td {

  background: rgba(255, 255, 255, 0.2);

}

.fc-button {

  background: #ffffff;

  border: 1px solid #d7ecfb;

  color: #555;

  text-transform: capitalize;

}

.fc-text-arrow {

  font-size: 16px;

}

.fc-state-hover {

  background: #f3f3f3;

}

.fc-state-highlight,

.fc-cell-overlay {

  background: #f0f0f0;

}

.fc-unthemed .fc-today {

  background: #ffffff;

}

.fc .fc-event,

#external-events .fc-event {

  -webkit-border-radius: 2px;

  -moz-border-radius: 2px;

  border-radius: 2px;

  border: none;

  cursor: move;

  font-size: 12px;

  margin: 5px 7px 5px 7px;

  padding: 5px;

  text-align: center;

}

.external-event {

  color: #ffffff;

  cursor: move;

  margin: 10px 0px 10px 0px;

  padding: 6px 10px 6px 10px;

}

.fc-basic-view td.fc-week-number span,

.fc-basic-view td.fc-day-number {

  padding-right: 5px;

}

.fc-toolbar .fc-button-group .fc-button {

  background: #ffffff;

}

.fc-toolbar .fc-button-group .fc-button:hover {

  background: #F5F5F5;

}

.fc .fc-event,

.fc .fc-event-dot {

  background: #1d84df;

  transition: none;

  -moz-transition: none;

  -webkit-transition: none;

}

.fc .fc-event-dot {

  background: #ffffff;

}

.fc-ltr .fc-basic-view .fc-day-top .fc-day-number {

  color: #435966;

}

.fc.fc-ltr .fc-h-event.fc-not-start,

.fc.fc-rtl .fc-h-event.fc-not-end {

  margin-left: 7px;

}

@media (max-width: 479px) {

  .fc .fc-toolbar .fc-right {

    width: 100%;

    text-align: left;

    margin-top: 15px;

    margin-bottom: 25px;

  }

  .fc .fc-view-container {

    overflow: auto;

  }

  .fc .fc-view-container .fc-view {

    width: 400px;

  }

}

/*---------------------------------------------------------------------*/

/*-----------------------------------------------------------------------

4. Chartist Chart

*/

.chartist-chart .ct-label {

  font-size: 12px;

}

.chartist-chart-pie .ct-label {

  font-size: 14px;

  color: #ffffff;

  fill: #fff;

}

/*---------------------------------------------------------------------*/

/*-----------------------------------------------------------------------

5. Checkbox

*/

.checkbox input[type="checkbox"] {

  display: none;

}

.checkbox input[type="checkbox"]:checked + label:before {

  border-color: #415dfb;

  background: #415dfb;

}

.checkbox input[type="checkbox"]:checked + label:after {

  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";

  -moz-opacity: 1;

  -khtml-opacity: 1;

  opacity: 1;

}

.checkbox input[type="checkbox"]:disabled + label {

  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";

  -moz-opacity: 0.6;

  -khtml-opacity: 0.6;

  opacity: 0.6;

}

.checkbox label {

  position: relative;

  padding-left: 25px;

}

.checkbox label:before {

  content: "";

  position: absolute;

  top: 1px;

  left: 0px;

  width: 17px;

  height: 17px;

  border: 1px solid #ccc;

  background: #ffffff;

  transition: all 0.4s ease;

  -moz-transition: all 0.4s ease;

  -o-transition: all 0.4s ease;

  -ms-transition: all 0.4s ease;

  -webkit-transition: all 0.4s ease;

}

.checkbox label:after {

  content: "\f00c";

  font-family: 'FontAwesome';

  font-size: 12px;

  line-height: 15px;

  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";

  -moz-opacity: 0;

  -khtml-opacity: 0;

  opacity: 0;

  position: absolute;

  top: 2px;

  left: 2px;

  color: #ffffff;

  transition: all 0.4s ease;

  -moz-transition: all 0.4s ease;

  -o-transition: all 0.4s ease;

  -ms-transition: all 0.4s ease;

  -webkit-transition: all 0.4s ease;

}

.checkbox.primary input[type="checkbox"]:checked + label:before {

  background: #188ae2;

  border-color: #188ae2;

}

.checkbox.success input[type="checkbox"]:checked + label:before {

  background: #00bf4f;

  border-color: #00bf4f;

}

.checkbox.info input[type="checkbox"]:checked + label:before {

  background: #00aeff;

  border-color: #00aeff;

}

.checkbox.warning input[type="checkbox"]:checked + label:before {

  background: #fbbc05;

  border-color: #fbbc05;

}

.checkbox.danger input[type="checkbox"]:checked + label:before {

  background: #ea4335;

  border-color: #ea4335;

}

.checkbox.purple input[type="checkbox"]:checked + label:before {

  background: #5b69bc;

  border-color: #5b69bc;

}

.checkbox.pink input[type="checkbox"]:checked + label:before {

  background: #ff8acc;

  border-color: #ff8acc;

}

.checkbox.inverse input[type="checkbox"]:checked + label:before {

  background: #212121;

  border-color: #212121;

}

.checkbox.circled label:before {

  -webkit-border-radius: 100%;

  -moz-border-radius: 100%;

  border-radius: 100%;

}

/*---------------------------------------------------------------------*/

/*-----------------------------------------------------------------------

6. Data Tables

*/

.dataTables_wrapper .dataTables_paginate .pagination {

  margin: 0;

}

.dataTables_wrapper .dataTables_paginate .pagination .paginate_button {

  padding: 0px;

  min-width: 0px;

  margin-left: 10px;

  border: none;

  background: none;

}

.dataTables_wrapper .dataTables_paginate .pagination .paginate_button:first-child {

  margin-left: 0px;

}

.dataTables_wrapper .dataTable {

  margin-top: 10px!important;

  margin-bottom: 18px!important;

}

.dataTables_wrapper .dataTable .group {

  background-color: #1d84df;

  color: #ffffff;

}

.dataTables_wrapper .dataTables_scrollBody .dataTable {

  margin-top: 0px!important;

  margin-bottom: 0px!important;

}

.dataTables_wrapper .dataTables_scrollFoot .dataTable {

  margin-top: 0px!important;

}

.dataTables_wrapper table.dataTable thead th,

.dataTables_wrapper table.dataTable thead th,

.dataTables_wrapper table.dataTable thead td,

.dataTables_wrapper table.dataTable thead td {

  border-bottom-color: #ddd;

  padding-left: 8px;

  padding-right: 8px;

  border-bottom-width: 1px;

}

table.focus-on > tbody > tr.focused.unfocused > th,

table.focus-on > tbody > tr.focused.unfocused > td {

  background: #1d84df;

  color: #ffffff;

}

/*---------------------------------------------------------------------*/

/*-----------------------------------------------------------------------

7. Dropcap

*/

.dropcap,

.dropcap-circle,

.dropcap-square {

  display: block;

  float: left;

  font-weight: 400;

  line-height: 36px;

  margin-right: 6px;

  text-shadow: none;

}

.dropcap {

  font-size: 3.1em;

}

.dropcap-circle {

  font-size: 26px;

  line-height: 36px;

  width: 36px;

  text-align: center;

  -webkit-border-radius: 100%;

  -moz-border-radius: 100%;

  border-radius: 100%;

}

.dropcap-square {

  font-size: 26px;

  line-height: 36px;

  width: 36px;

  text-align: center;

}

/*---------------------------------------------------------------------*/

/*-----------------------------------------------------------------------

8. Example Content

*/

.example-content {

  position: relative;

  padding: 15px;

  background: #f5f5f5;

}

.example-content .modal {

  display: block;

  position: relative;

  top: auto;

  left: auto;

  right: auto;

  bottom: auto;

  z-index: 10;

}

.example-content .modal-dialog {

  left: auto;

  margin-right: auto;

  margin-left: auto;

}

.bs-example {

  position: relative;

  padding: 45px 15px 15px 15px;

  margin: 0px;

  border: 1px solid #ddd;

  background: #f4f4f4;

}

.bs-example .bs-title {

  position: absolute;

  top: 15px;

  left: 15px;

  font-size: 12px;

  font-weight: 700;

  color: #435966;

  text-transform: uppercase;

}

.bs-example > .dropdown > .dropdown-toggle {

  float: left;

}

.bs-example > .dropdown > .dropdown-menu {

  position: static;

  display: block;

  margin-bottom: 5px;

  clear: left;

}

/*---------------------------------------------------------------------*/

/*-----------------------------------------------------------------------

9. Inbox List

*/

.inbox-list {

  margin-bottom: 15px;

  list-style: none;

  padding: 0px;

}

.inbox-list li {

  border-bottom: 1px solid #f3f3f3;

}

.inbox-list .avatar {

  position: absolute;

  top: 10px;

  left: 0px;

  width: 40px;

  height: 40px;

  -webkit-border-radius: 100%;

  -moz-border-radius: 100%;

  border-radius: 100%;

}

.inbox-list .inbox-item {

  position: relative;

  min-height: 40px;

  padding: 10px 0px 10px 55px;

  transition: all 0.4s ease;

  -moz-transition: all 0.4s ease;

  -o-transition: all 0.4s ease;

  -ms-transition: all 0.4s ease;

  -webkit-transition: all 0.4s ease;

}

.inbox-list .name {

  color: #1d84df;

  margin: 0px;

  transition: all 0.4s ease;

  -moz-transition: all 0.4s ease;

  -o-transition: all 0.4s ease;

  -ms-transition: all 0.4s ease;

  -webkit-transition: all 0.4s ease;

  font-size: 14px;

  line-height: 22px;

  overflow: hidden;

  white-space: nowrap;

  text-overflow: ellipsis;

  font-weight: 500;

}

@media (min-width: 1025px) {

  .inbox-list .name:hover {

    color: #304ffe;

  }

}

.inbox-list .text {

  color: #656d78;

  font-size: 12px;

  overflow: hidden;

  white-space: nowrap;

  text-overflow: ellipsis;

  margin: 0px;

  font-weight: 500;

}

.inbox-list .time {

  font-size: 11px;

  color: #aab2bd;

  position: absolute;

  top: 10px;

  right: 0px;

}

.inbox-list .full-text .text {

  white-space: normal;

}

.inbox-read-more {

  display: block;

  color: #435966;

  text-align: center;

}

/*---------------------------------------------------------------------*/

/*-----------------------------------------------------------------------

10. Jquery UI

*/

.ui-sortable-placeholder {

  border: 2px dashed #435966;

  background: #ccc;

  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";

  -moz-opacity: 0.7;

  -khtml-opacity: 0.7;

  opacity: 0.7;

}

/*---------------------------------------------------------------------*/

/*-----------------------------------------------------------------------

11. Menu Mobile Button

*/

.menu-mobile-button {

  display: block;

  position: absolute;

  top: 12px;

  left: 0;

  height: 44px;

  width: 44px;

  border: none;

  outline: none;

  font-size: 20px;

  line-height: 0px;

  color: #55566b;

  background: transparent;

}

/*---------------------------------------------------------------------*/

/*-----------------------------------------------------------------------

12. Morris Chart

*/

.morris-chart-detail-list {

  margin-top: 10px;

}

.morris-chart-detail-list .fa {

  margin-right: 5px;

}

.morris-chart-detail-list li:nth-child(1) {

  color: #fcb03b;

}

.morris-chart-detail-list li:nth-child(2) {

  color: #ea65a2;

}

.morris-chart-detail-list li:nth-child(3) {

  color: #566FC9;

}

.morris-chart-detail-list li:nth-child(4) {

  color: #333333;

}

.morris-chart-realtime .morris-hover-row-label {

  display: none;

}

/*---------------------------------------------------------------------*/

/*-----------------------------------------------------------------------

13. Notice

*/

.notice {

  display: inline-block;

  -webkit-border-radius: 0.25em;

  -moz-border-radius: 0.25em;

  border-radius: 0.25em;

  padding: 2px 6px 0;

  font-size: 75%;

}

.notice-blue {

  color: #ffffff;

  background: #1d84df;

}

.notice-purple {

  color: #ffffff;

  background: #5b69bc;

}

.notice-yellow {

  color: #ffffff;

  background: #fbbc05;

}

.notice-danger {

  color: #ffffff;

  background: #ff1744;

}

/* Notice List */

.notice-list {

  list-style: none;

  padding: 0px;

}

.notice-list li {

  border-bottom: 1px solid #eaedef;

}

.notice-list a {

  display: block;

  position: relative;

  padding: 14px 15px 14px 85px;

  min-height: 75px;

}

.notice-list a:hover {

  background: #f5f7fa;

}

.notice-list .avatar {

  position: absolute;

  top: 10px;

  left: 15px;

  width: 55px;

  height: 55px;

  overflow: hidden;

  text-align: center;

  -webkit-border-radius: 100%;

  -moz-border-radius: 100%;

  border-radius: 100%;

}

.notice-list .avatar i {

  font-size: 24px;

  line-height: 55px;

  color: #ffffff;

  top: 0px;

}

.notice-list .desc {

  color: #90a4ae;

  font-size: 13px;

  line-height: 23px;

  text-overflow: ellipsis;

  white-space: nowrap;

  overflow: hidden;

  width: 100%;

  display: block;

}

.notice-list .name {

  font-size: 14px;

  line-height: 24px;

  font-weight: 500;

  color: #212121;

  transition: all 0.4s ease;

  -moz-transition: all 0.4s ease;

  -o-transition: all 0.4s ease;

  -ms-transition: all 0.4s ease;

  -webkit-transition: all 0.4s ease;

  text-overflow: ellipsis;

  white-space: nowrap;

  width: 100%;

  display: block;

}

@media (min-width: 1025px) {

  .notice-list .name:hover {

    color: #1d84df;

  }

}

.notice-list .time {

  font-size: 12px;

  text-overflow: ellipsis;

  white-space: nowrap;

  display: block;

  color: #aab2bd;

  position: absolute;

  right: 20px;

  top: 17px;

}

/* Notice Popup */

.notice-popup {

  opacity: 0;

  visibility: hidden;

  position: fixed;

  top: 75px;

  right: 20px;

  z-index: 40;

  background: #ffffff;

  width: 450px;

  max-width: 100%;

  padding-top: 50px;

  transform: translate(0, 30px);

  -webkit-transform: translate(0, 30px);

  -moz-transform: translate(0, 30px);

  -o-transform: translate(0, 30px);

  -ms-transform: translate(0, 30px);

  transition: all 0.4s ease;

  -moz-transition: all 0.4s ease;

  -o-transition: all 0.4s ease;

  -ms-transition: all 0.4s ease;

  -webkit-transition: all 0.4s ease;

  box-shadow: 0 0px 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);

  -webkit-box-shadow: 0 0px 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);

  -moz-box-shadow: 0 0px 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);

}

@media (max-width: 550px) {

  .notice-popup {

    width: 100%;

    right: 0;

  }

  .notice-popup#message-popup {

    right: 0;

  }

}

.notice-popup.active {

  opacity: 1;

  visibility: visible;

  transform: translate(0, 0);

  -webkit-transform: translate(0, 0);

  -moz-transform: translate(0, 0);

  -o-transform: translate(0, 0);

  -ms-transform: translate(0, 0);

}

.notice-popup .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {

  background: #aab2bd;

}

.notice-popup:hover .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {

  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";

  -moz-opacity: 1;

  -khtml-opacity: 1;

  opacity: 1;

}

.notice-popup .content {

  overflow: auto;

  height: 100%;

}

.notice-popup .content .notice-list {

  width: 100%;

  overflow: hidden;

}

.notice-popup .popup-title {

  position: absolute;

  top: 0px;

  left: 0px;

  width: 100%;

  height: 50px;

  padding: 11px 10px 11px 18px;

  color: #1b1f20;

  margin: 0px;

  border-bottom: 1px solid #e6e9ed;

  font-weight: 600;

  letter-spacing: 0.03em;

  text-transform: uppercase;

  font-size: 14px;

  line-height: 27px;

}

.notice-popup .popup-title a {

  text-transform: initial;

  font-weight: 400;

}

.notice-popup .popup-close {

  position: absolute;

  top: 0px;

  right: 0px;

  z-index: 10;

  font-size: 18px;

  line-height: 59px;

  padding: 0px 10px 0px 10px;

  color: #435966;

}

@media (min-width: 1025px) {

  .notice-popup .popup-close:hover {

    color: #ea4335;

  }

}

.notice-popup .notice-read-more {

  font-size: 14px;

  line-height: 24px;

  color: #212121;

  padding: 3px 10px 13px;

  text-align: center;

  display: block;

}

.notice-popup .notice-read-more:hover {

  color: #1d84df;

}

#message-popup {

  right: 60px;

}

/*---------------------------------------------------------------------*/

/*-----------------------------------------------------------------------

14. noUiSlider

*/

.noui-connect .noUi-origin {

  right: auto;

  width: 0px;

}

.noui-connect .connect {

  position: absolute;

  top: 0px;

  bottom: 0px;

  background: #80C9F5;

  box-shadow: inset 0 0 3px rgba(51, 51, 51, 0.45);

  -moz-box-shadow: inset 0 0 3px rgba(51, 51, 51, 0.45);

  -webkit-box-shadow: inset 0 0 3px rgba(51, 51, 51, 0.45);

}

.noui-connect.noUi-state-tap .connect {

  -webkit-transition: left 300ms, right 300ms;

  -moz-transition: left 300ms, right 300ms;

  transition: left 300ms, right 300ms;

}

.noui-select,

.noui-number {

  padding: 7px;

  margin: 15px 5px 5px 5px;

  width: 70px;

}

/*---------------------------------------------------------------------*/

/*-----------------------------------------------------------------------

15. Process Bar

*/

.process-bar {

  display: block;

  position: relative;

  width: 100%;

  height: 5px;

  margin-top: 12px;

  background: #ffffff;

  overflow: hidden;

}

.process-bar .bar {

  position: absolute;

  top: 0px;

  left: 0px;

  height: 5px;

  z-index: 2;

}

.process-bar .bar-bg {

  position: absolute;

  top: 0;

  left: 0;

  height: 5px;

  z-index: 1;

  opacity: 0.2;

  width: 100%;

}

/*---------------------------------------------------------------------*/

/*-----------------------------------------------------------------------

16. Radio

*/

.radio input[type="radio"] {

  display: none;

}

.radio input[type="radio"]:checked + label:before {

  border-color: #435966;

}

.radio input[type="radio"]:checked + label:after {

  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";

  -moz-opacity: 1;

  -khtml-opacity: 1;

  opacity: 1;

}

.radio input[type="radio"]:disabled + label {

  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";

  -moz-opacity: 0.6;

  -khtml-opacity: 0.6;

  opacity: 0.6;

}

.radio label {

  position: relative;

  padding-left: 25px;

}

.radio label:before {

  content: "";

  position: absolute;

  top: 1px;

  left: 0px;

  width: 17px;

  height: 17px;

  border: 1px solid #ccc;

  transition: all 0.4s ease;

  -moz-transition: all 0.4s ease;

  -o-transition: all 0.4s ease;

  -ms-transition: all 0.4s ease;

  -webkit-transition: all 0.4s ease;

  -webkit-border-radius: 100%;

  -moz-border-radius: 100%;

  border-radius: 100%;

}

.radio label:after {

  content: "";

  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";

  -moz-opacity: 0;

  -khtml-opacity: 0;

  opacity: 0;

  position: absolute;

  top: 4px;

  left: 3px;

  width: 11px;

  height: 11px;

  background: #435966;

  -webkit-border-radius: 100%;

  -moz-border-radius: 100%;

  border-radius: 100%;

  transition: all 0.4s ease;

  -moz-transition: all 0.4s ease;

  -o-transition: all 0.4s ease;

  -ms-transition: all 0.4s ease;

  -webkit-transition: all 0.4s ease;

}

.radio.primary label:after {

  background: #1d84df;

}

.radio.success label:after {

  background: #00bf4f;

}

.radio.info label:after {

  background: #00aeff;

}

.radio.warning label:after {

  background: #fbbc05;

}

.radio.danger label:after {

  background: #ea4335;

}

.radio.purple label:after {

  background: #5b69bc;

}

.radio.pink label:after {

  background: #ff8acc;

}

.radio.inverse label:after {

  background: #212121;

}

.radio.primary input[type="radio"]:checked + label:before {

  border-color: #1d84df;

}

.radio.success input[type="radio"]:checked + label:before {

  border-color: #00bf4f;

}

.radio.info input[type="radio"]:checked + label:before {

  border-color: #00aeff;

}

.radio.warning input[type="radio"]:checked + label:before {

  border-color: #fbbc05;

}

.radio.danger input[type="radio"]:checked + label:before {

  border-color: #ea4335;

}

.radio.purple input[type="radio"]:checked + label:before {

  order-color: #5b69bc;

}

.radio.pink input[type="radio"]:checked + label:before {

  border-color: #ff8acc;

}

.radio.inverse input[type="radio"]:checked + label:before {

  border-color: #212121;

}

/*---------------------------------------------------------------------*/

/*-----------------------------------------------------------------------

17. Search Form

*/

.searchform {

  display: inline-block;

  position: relative;

  margin-left: 20px;

  padding: 13px 0px 13px 0px;

}

.searchform .input-search {

  border: none;

  width: 190px;

  height: 34px;

  padding: 7px 40px 7px 20px;

  font-size: 13px;

  line-height: 20px;

  color: #505458;

  background: #ffffff;

  -webkit-border-radius: 30px;

  -moz-border-radius: 30px;

  border-radius: 30px;

}

.searchform .input-search::-webkit-input-placeholder {

  color: #999999;

}

.searchform .input-search:-moz-input-placeholder {

  color: #999999;

}

.searchform .input-search::-moz-input-placeholder {

  color: #999999;

}

.searchform .input-search:-ms-input-placeholder {

  color: #999999;

}

.searchform .button-search {

  position: absolute;

  top: 22px;

  right: 10px;

  color: #435966;

  z-index: 10;

  background: none;

  outline: none;

  border: none;

}

@media (min-width: 1025px) {

  .searchform .button-search:hover {

    color: #1d84df;

  }

}

/*---------------------------------------------------------------------*/

/*-----------------------------------------------------------------------

18. Statistics Box

*/

.statistics-box {

  padding-top: 14px;

  min-height: 80px;

  text-align: center;

}

.statistics-box.with-icon {

  position: relative;

  padding-left: 80px;

  text-align: right;

}

.statistics-box.with-icon .ico {

  position: absolute;

  top: 0px;

  left: 0px;

  width: 80px;

  height: 80px;

  font-size: 70px;

  line-height: 80px;

  text-align: center;

}

.statistics-box.with-icon .ico.small {

  font-size: 42px;

}

.statistics-box .counter {

  margin-top: 0px;

  margin-bottom: 0px;

}

.statistics-box .text {

  color: #aab2bd;

  margin: 0px;

}

/*---------------------------------------------------------------------*/

/*-----------------------------------------------------------------------

19. Switch

*/

.switch {

  display: block;

  margin-bottom: 10px;

}

.switch input[type="checkbox"] {

  display: none;

}

.switch input[type="checkbox"]:checked + label:before {

  background: #435966;

  border-color: #435966;

}

.switch input[type="checkbox"]:checked + label:after {

  left: 14px;

}

.switch label {

  position: relative;

  padding-left: 40px;

  font-weight: 400;

  cursor: pointer;

  margin: 0px;

  min-height: 20px;

}

.switch label:before {

  content: "";

  width: 30px;

  height: 18px;

  position: absolute;

  top: 1px;

  left: 0px;

  border: 1px solid #ddd;

  -webkit-border-radius: 8px;

  -moz-border-radius: 8px;

  border-radius: 8px;

  background: #f1f1f1;

  transition: all 0.4s ease;

  -moz-transition: all 0.4s ease;

  -o-transition: all 0.4s ease;

  -ms-transition: all 0.4s ease;

  -webkit-transition: all 0.4s ease;

}

.switch label:after {

  content: "";

  width: 16px;

  height: 16px;

  -webkit-border-radius: 100%;

  -moz-border-radius: 100%;

  border-radius: 100%;

  position: absolute;

  top: 2px;

  left: 0px;

  z-index: 10;

  background: #ffffff;

  transition: all 0.4s ease;

  -moz-transition: all 0.4s ease;

  -o-transition: all 0.4s ease;

  -ms-transition: all 0.4s ease;

  -webkit-transition: all 0.4s ease;

  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);

  -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);

  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);

}

.switch.primary input[type="checkbox"]:checked + label:before {

  background: #1d84df;

  border-color: #1d84df;

}

.switch.success input[type="checkbox"]:checked + label:before {

  background: #00bf4f;

  border-color: #00bf4f;

}

.switch.info input[type="checkbox"]:checked + label:before {

  background: #00aeff;

  border-color: #00aeff;

}

.switch.warning input[type="checkbox"]:checked + label:before {

  background: #fbbc05;

  border-color: #fbbc05;

}

.switch.danger input[type="checkbox"]:checked + label:before {

  background: #ea4335;

  border-color: #ea4335;

}

.switch.purple input[type="checkbox"]:checked + label:before {

  background: #5b69bc;

  border-color: #5b69bc;

}

.switch.pink input[type="checkbox"]:checked + label:before {

  background: #ff8acc;

  border-color: #ff8acc;

}

.switch.inverse input[type="checkbox"]:checked + label:before {

  background: #212121;

  border-color: #212121;

}

/*---------------------------------------------------------------------*/

/*-----------------------------------------------------------------------

20. Tabs

*/

.tab-content {

  padding: 15px;

  border: 1px solid #ddd;

  border-top: none;

}

.tab-header {

  padding: 15px;

  border: 1px solid #ddd;

}

.tab-header .navbar {

  margin-bottom: 0px;

  border: none;

  min-height: auto;

}

.tab-header.pill {

  border: none;

  padding: 0px;

}

/*---------------------------------------------------------------------*/

/*-----------------------------------------------------------------------

21. Timepicke

*/

.bootstrap-timepicker-widget table td a:hover {

  background-color: transparent;

  border-color: transparent;

  border-radius: 4px;

  color: #188ae2;

  text-decoration: none;

}

.bootstrap-timepicker-widget table td input {

  border: none;

}

/*---------------------------------------------------------------------*/

/*-----------------------------------------------------------------------

22. Title

*/

.page-title {

  display: inline-block;

  margin: 0px;

  font-size: 18px;

  line-height: 75px;

  font-weight: 500;

}

/*---------------------------------------------------------------------*/

/*-----------------------------------------------------------------------

23. User Info

*/

.user-info .avatar {

  float: left;

  width: 78px;

}

.user-info .avatar img {

  border: 3px solid #ffffff;

  -webkit-border-radius: 100%;

  -moz-border-radius: 100%;

  border-radius: 100%;

  -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);

  -moz-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);

  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);

}

.user-info .name,

.user-info p,

.user-info .text-custom {

  display: block;

  overflow: hidden;

  white-space: nowrap;

  text-overflow: ellipsis;

}

.user-info .text-custom {

  font-weight: 500;

}

.user-info .name {

  margin: 0px 0px 5px 0px;

  font-weight: 600;

  font-size: 16px;

  line-height: 22px;

}

.user-info .right-content {

  margin-left: 90px;

}

.user-info p {

  font-size: 13px;

  line-height: 22px;

  margin-bottom: 5px;

}

.user-info a {

  color: #2962ff;

  font-size: 13px;

}

/*---------------------------------------------------------------------*/

/*-----------------------------------------------------------------------

24. Widget Stat

*/

.widget-stat {

  padding-bottom: 10px;

  text-align: right;

}

.widget-stat .counter {

  padding-top: 10px;

  margin-top: 0px;

  margin-bottom: 0px;

  color: #434a54;

}

.widget-stat .percent {

  float: left;

  padding: 0px 10px 0px 10px;

  margin-top: 20px;

  font-size: 12px;

  line-height: 22px;

  color: #ffffff;

  -webkit-border-radius: 10px;

  -moz-border-radius: 10px;

  border-radius: 10px;

}

.widget-stat .percent i {

  font-size: 12px;

  line-height: 22px;

  margin-right: 4px;

}

.widget-stat .left-content {

  float: left;

}

.widget-stat .right-content {

  margin-left: 80px;

}

.widget-stat .text {

  color: #aab2bd;

  white-space: nowrap;

  text-overflow: ellipsis;

  overflow: hidden;

}

/*---------------------------------------------------------------------*/

/*-----------------------------------------------------------------------

25. Widget Stat Chart

*/

.widget-stat-chart {

  padding-bottom: 10px;

  text-align: right;

}

.widget-stat-chart .c100 {

  margin: 0px;

}

.widget-stat-chart .counter {

  padding-top: 10px;

  margin-top: 0px;

  margin-bottom: 0px;

}

.widget-stat-chart .right-content {

  margin-left: 80px;

}

.widget-stat-chart .text {

  color: #aab2bd;

  white-space: nowrap;

  text-overflow: ellipsis;

  overflow: hidden;

  font-weight: 600;

}

/*---------------------------------------------------------------------*/

/*-----------------------------------------------------------------------

26. Misc

*/

.form-inline .checkbox label,

.form-inline .radio label {

  padding-left: 25px;

}

.label {

  padding: .2em .6em .2em;

  font-weight: 400;

}

.table > tbody > tr > td,

.table > tbody > tr > th,

.table > tfoot > tr > td,

.table > tfoot > tr > th,

.table > thead > tr > td,

.table > thead > tr > th {

  padding: 9px;

}

@media (min-width: 768px) {

  .form-horizontal .control-label {

    padding-top: 12px;

  }

}

.ui-accordion .ui-accordion-header {

  outline: none;

}

.list-group-item,

.list-group-item:first-child,

.list-group-item:last-child {

  border-radius: 0px;

  border-color: rgba(120, 130, 140, 0.13);

}

.table-purchases a {

  color: #ccc;

}

.table-purchases a:hover {

  color: #999;

}

/*---------------------------------------------------------------------*/

/*-----------------------------------------------------------------------

27. Mailbox

*/

.box {

  position: relative;

  width: 100%;

  margin-bottom: 20px;

  background: #ffffff;

  border: 1px solid #e1e3e5;

  -webkit-border-radius: 4px;

  -moz-border-radius: 4px;

  border-radius: 4px;

  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);

  -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);

  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);

}

.box.collapsed-box .box-body,

.box.collapsed-box .box-footer {

  display: none;

}

.box .nav-stacked > li {

  border-bottom: 1px solid #f4f4f4;

  margin: 0px;

}

.box .nav-stacked > li:last-of-type {

  border-bottom: none;

}

.box .border-right {

  border-right: 1px solid #f4f4f4;

}

.box .border-left {

  border-left: 1px solid #f4f4f4;

}

.box.box-solid {

  border-top: none;

}

.box.box-solid > .box-header .btn.btn-default {

  background: none;

}

.box.box-solid > .box-header .btn:hover,

.box.box-solid > .box-header a:hover {

  background: rgba(0, 0, 0, 0.1);

}

.box.box-solid.box-default {

  border: 1px solid #d2d6de;

}

.box.box-solid.box-default > .box-header {

  color: #444;

  background-color: #d2d6de;

}

.box.box-solid.box-default > .box-header a,

.box.box-solid.box-default > .box-header .btn {

  color: #444;

}

.box.box-solid.box-primary {

  border: 1px solid #1d84df;

}

.box.box-solid.box-primary > .box-header {

  color: #ffffff;

  background-color: #1d84df;

}

.box.box-solid.box-primary > .box-header a,

.box.box-solid.box-primary > .box-header .btn {

  color: #ffffff;

}

.box.box-solid.box-success {

  border: 1px solid #00bf4f;

}

.box.box-solid.box-success > .box-header {

  color: #ffffff;

  background-color: #00bf4f;

}

.box.box-solid.box-success > .box-header a,

.box.box-solid.box-success > .box-header .btn {

  color: #ffffff;

}

.box.box-solid.box-info {

  border: 1px solid #00aeff;

}

.box.box-solid.box-info > .box-header {

  color: #ffffff;

  background-color: #00aeff;

}

.box.box-solid.box-info > .box-header a,

.box.box-solid.box-info > .box-header .btn {

  color: #ffffff;

}

.box.box-solid.box-warning {

  border: 1px solid #fbbc05;

}

.box.box-solid.box-warning > .box-header {

  color: #ffffff;

  background-color: #fbbc05;

}

.box.box-solid.box-warning > .box-header a,

.box.box-solid.box-warning > .box-header .btn {

  color: #ffffff;

}

.box.box-solid.box-danger {

  border: 1px solid #ea4335;

}

.box.box-solid.box-danger > .box-header {

  color: #ffffff;

  background-color: #ea4335;

}

.box.box-solid.box-danger > .box-header a,

.box.box-solid.box-danger > .box-header .btn {

  color: #ffffff;

}

.box.box-solid.box-purple {

  border: 1px solid #5b69bc;

}

.box.box-solid.box-purple > .box-header {

  color: #ffffff;

  background-color: #5b69bc;

}

.box.box-solid.box-purple > .box-header a,

.box.box-solid.box-purple > .box-header .btn {

  color: #ffffff;

}

.box.box-solid.box-pink {

  border: 1px solid #ff8acc;

}

.box.box-solid.box-pink > .box-header {

  color: #ffffff;

  background-color: #ff8acc;

}

.box.box-solid.box-pink > .box-header a,

.box.box-solid.box-pink > .box-header .btn {

  color: #ffffff;

}

.box.box-solid.box-inverse {

  border: 1px solid #212121;

}

.box.box-solid.box-inverse > .box-header {

  color: #ffffff;

  background-color: #212121;

}

.box.box-solid.box-inverse > .box-header a,

.box.box-solid.box-inverse > .box-header .btn {

  color: #ffffff;

}

.box.box-solid[class*='bg'] > .box-header {

  color: #ffffff;

}

.box > .box-header > .box-tools .btn {

  border: none;

  box-shadow: none;

  -moz-box-shadow: none;

  -webkit-box-shadow: none;

}

.box .box-header:before,

.box .box-body:before,

.box .box-footer:before,

.box .box-header:after,

.box .box-body:after,

.box .box-footer:after {

  content: "";

  display: table;

}

.box .box-header:after,

.box .box-body:after,

.box .box-footer:after {

  clear: both;

}

.box .box-header {

  display: block;

  position: relative;

  padding: 14px 20px;

  color: #444;

}

.box .box-header.with-border {

  border-bottom: 1px solid #f2f4f6;

}

.box .box-header > .fa,

.box .box-header > .glyphicon,

.box .box-header .box-title,

.box .box-header .ico {

  display: inline-block;

  font-size: 18px;

  line-height: 20px;

  margin: 0px;

  margin-right: 5px;

  font-weight: 500;

}

.box .box-header > .box-tools {

  position: absolute;

  top: 7px;

  right: 20px;

}

.box .box-header > .box-tools [data-toggle="tooltip"] {

  position: relative;

}

.box .box-header > .box-tools.pull-right .dropdown-menu {

  right: 0px;

  left: auto;

}

@media (max-widht: 479px) {

  .box .box-header .has-feedback {

    display: none;

  }

}

.box .box-header .has-feedback .form-control {

  width: 300px;

  height: 35px;

}

.box .box-header .has-feedback .form-control-feedback {

  width: 35px;

  height: 35px;

  line-height: 35px;

  color: #656d78;

}

.box.collapsed-box .box-header.with-border {

  border-bottom: none;

}

.btn-mail-main {

  height: 50px;

  font-size: 14px;

  line-height: 34px;

}

.btn-box-tool {

  padding: 5px;

  font-size: 12px;

  background: none;

  color: #97a0b3;

}

.btn-box-tool.btn:active {

  box-shadow: none;

  -webkit-box-shadow: none;

  -moz-box-shadow: none;

}

.open .btn-box-tool,

.btn-box-tool:hover {

  color: #606c84;

}

.box-body {

  padding: 0px 20px;

  -moz-border-radius-topleft: 0px;

  -webkit-border-top-left-radius: 0px;

  border-top-left-radius: 0px;

  -moz-border-radius-topright: 0px;

  -webkit-border-top-right-radius: 0px;

  border-top-right-radius: 0px;

  -moz-border-radius-bottomleft: 3px;

  -webkit-border-bottom-left-radius: 3px;

  border-bottom-left-radius: 3px;

  -moz-border-radius-bottomright: 3px;

  -webkit-border-bottom-right-radius: 3px;

  border-bottom-right-radius: 3px;

}

.box-body > .table {

  margin-bottom: 0px;

}

.box-body .nav li.active a {

  background: #ffffff;

  color: #1d84df;

}

.box-body .nav a {

  padding: 13px 0;

  color: #212121;

  font-size: 14px;

  line-height: 25px;

  font-weight: 500;

}

.box-body .nav a:hover {

  background: #ffffff;

  color: #1d84df;

}

.box-body .nav .fa {

  display: inline-block;

  width: 35px;

  line-height: 26px;

  font-size: 17px;

}

.box-body .nav .label-text-right {

  color: #656d78;

  font-size: 14px;

  line-height: 25px;

}

.box-body .nav .label-right {

  padding: 0px 7px;

  font-size: 14px;

  line-height: 20px;

  margin-top: 2px;

  padding-top: 2px;

  border-radius: 4px;

  -moz-border-radius: 4px;

  -webkit-border-radius: 4px;

  font-weight: 400;

}

.box-footer {

  padding: 10px 20px;

  background: #ffffff;

  -moz-border-radius-topleft: 0px;

  -webkit-border-top-left-radius: 0px;

  border-top-left-radius: 0px;

  -moz-border-radius-topright: 0px;

  -webkit-border-top-right-radius: 0px;

  border-top-right-radius: 0px;

  -moz-border-radius-bottomleft: 3px;

  -webkit-border-bottom-left-radius: 3px;

  border-bottom-left-radius: 3px;

  -moz-border-radius-bottomright: 3px;

  -webkit-border-bottom-right-radius: 3px;

  border-bottom-right-radius: 3px;

}

.mailbox-messages .table {

  margin-bottom: 0;

  border-collapse: separate;

  border-spacing: 0px 1px;

}

.mailbox-messages .table > tbody > tr > td {

  border-top: none;

}

.mailbox-messages .table .mailbox-subject {

  white-space: nowrap;

  text-overflow: ellipsis;

  max-width: 500px;

  overflow: hidden;

}

.mailbox-messages .mailbox-star a {

  color: #ccd1d9;

  font-size: 17px;

}

.mailbox-messages .mailbox-name a {

  color: #212121;

  font-weight: 500;

}

.mailbox-messages .mailbox-subject {

  color: #90a4ae;

}

.mailbox-messages .mailbox-subject span {

  color: #656d78;

  font-weight: 500;

}

.mailbox-messages .table-striped > tbody > tr:nth-of-type(odd) {

  background: #f5f7fa;

}

.mailbox-messages .unread > td:first-of-type {

  border-left: 5px solid #1d84df;

}

.mailbox-messages .unread .mailbox-subject span {

  color: #212121;

}

.mailbox-messages tr > td:first-of-type {

  border-left: 5px solid transparent;

}

.mailbox-read-info {

  border-bottom: 1px solid #f4f4f4;

  padding: 10px 20px;

}

.mailbox-read-info h3 {

  font-size: 20px;

  margin: 0px;

}

.mailbox-read-info h5 {

  padding: 5px 0px 0px 0px;

  margin: 0px;

}

.mailbox-read-time {

  color: #999;

  font-size: 13px;

}

.mailbox-controls {

  border-bottom: 1px solid #f4f4f4;

  padding: 8px 20px 1px 15px;

}

.mailbox-controls:after {

  content: '';

  display: block;

  clear: both;

}

.mailbox-controls .btn {

  padding: 0;

  line-height: 35px;

  height: 35px;

  min-width: 80px;

  margin: 0 5px 7px;

  font-size: 18px;

  color: #656d78;

  background: #f5f7fa;

  border-color: #ccd1d9;

}

.mailbox-controls .btn-group {

  font-size: 0;

}

.mailbox-controls .btn-group .btn {

  min-width: 40px;

  color: #aab2bd;

  font-size: 26px;

  background: #f5f7fa;

  border: 1px solid #ccd1d9;

  margin: 0;

  z-index: 1;

}

.mailbox-controls .btn-group .btn:first-child {

  border-right: none;

}

.mailbox-controls .inbox-text {

  display: inline-block;

  font-size: 16px;

  line-height: 35px;

  margin-right: 8px;

}

@media (max-width: 929px) {

  .mailbox-controls .pull-right {

    width: 100%;

    padding: 10px;

  }

}

.icheckbox_square-blue {

  transform: scale(0.7);

}

.mailbox-read-message {

  padding: 10px 20px;

}

.mailbox-footer {

  padding: 10px 20px;

  border-bottom: 1px solid #f4f4f4;

  border-top: 1px solid #f4f4f4;

}

.mailbox-attachments {

  list-style: none;

  padding: 0px;

}

.mailbox-attachments li {

  float: left;

  width: 200px;

  border: 1px solid #eee;

  margin-bottom: 10px;

  margin-right: 10px;

}

.mailbox-attachment-name {

  font-weight: 700;

  color: #666;

}

.mailbox-attachment-icon,

.mailbox-attachment-info,

.mailbox-attachment-size {

  display: block;

}

.mailbox-attachment-info {

  padding: 10px;

  background: #f4f4f4;

}

.mailbox-attachment-size {

  color: #999;

  font-size: 12px;

}

.mailbox-attachment-icon {

  text-align: center;

  font-size: 65px;

  color: #666;

  padding: 20px 10px 20px 10px;

}

.mailbox-attachment-icon.has-img {

  padding: 0px;

}

.mailbox-attachment-icon.has-img > img {

  max-width: 100%;

  height: auto;

}

/*---------------------------------------------------------------------*/

/*-----------------------------------------------------------------------

28. Login, Register, ... Form

*/

#single-wrapper {

  min-height: 100%;

  background: url('file:///C|/Users/Public/Documents/12-6-19/kombatlink/assets/images/sativa.png') top center repeat;

  overflow: hidden;

  width: 100%;

  padding: 0px 15px 0px 15px;

}

.frm-single {

  width: 100%;

  max-width: 400px;

  display: block;

  margin: 60px auto 60px;

  float: none;

  position: relative;

}

.frm-single:before,

.frm-single:after {

  content: "";

  display: block;

  position: absolute;

  top: 0px;

  left: 0px;

  z-index: 10;

  width: 100%;

  height: 100%;

  background: #ffffff;

  transform: rotate(2deg);

  -webkit-transform: rotate(2deg);

  -moz-transform: rotate(2deg);

  -o-transform: rotate(2deg);

  -ms-transform: rotate(2deg);

  box-shadow: 0 0 1px 0 #ccc;

  -webkit-box-shadow: 0 0 1px 0 #ccc;

  -moz-box-shadow: 0 0 1px 0 #ccc;

}

.frm-single:after {

  transform: rotate(1deg);

  -webkit-transform: rotate(1deg);

  -moz-transform: rotate(1deg);

  -o-transform: rotate(1deg);

  -ms-transform: rotate(1deg);

}

.frm-single .inside {

  background: #ffffff;

  position: relative;

  padding: 20px;

  z-index: 20;

  box-shadow: 0 0 1px 0 #ccc;

  -webkit-box-shadow: 0 0 1px 0 #ccc;

  -moz-box-shadow: 0 0 1px 0 #ccc;

}

.frm-single .title {

  margin-bottom: 5px;

  font-family: "Poppins", sans-serif;

  font-size: 20px;

  line-height: 34px;

  white-space: nowrap;

  color: #435966;

  text-align: center;

}

.frm-single .a-link {

  color: #999;

}

.frm-single .a-link:hover {

  color: #435966;

}

.frm-single .frm-title {

  color: #999;

  text-align: center;

  font-size: 16px;

  padding-bottom: 20px;

}

.frm-single .frm-input {

  display: block;

  position: relative;

  margin-bottom: 20px;

}

.frm-single .frm-input .frm-inp {

  width: 100%;

  height: 40px;

  padding: 0px;

  padding-left: 30px;

  border: 1px solid #ccc;

  font-size: 14px;

  line-height: 38px;

  border-left: 3px solid #999;

}

.frm-single .frm-input .frm-inp:focus {

  border-color: #1d84df;

  padding-left: 10px;

}

.frm-single .frm-input .frm-inp:focus + .frm-ico {

  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";

  -moz-opacity: 0;

  -khtml-opacity: 0;

  opacity: 0;

  transform: scale(0.8, 0.8);

  -webkit-transform: scale(0.8, 0.8);

  -moz-transform: scale(0.8, 0.8);

  -o-transform: scale(0.8, 0.8);

  -ms-transform: scale(0.8, 0.8);

}

.frm-single .frm-input .frm-ico {

  position: absolute;

  top: 0px;

  left: 3px;

  width: 30px;

  font-size: 18px;

  line-height: 40px;

  text-align: center;

  color: #999;

  transition: all 0.4s ease;

  -moz-transition: all 0.4s ease;

  -o-transition: all 0.4s ease;

  -ms-transition: all 0.4s ease;

  -webkit-transition: all 0.4s ease;

}

.frm-single .checkbox {

  margin: 0px;

}

.frm-single .a-link i {

  margin-right: 5px;

}

.frm-single .frm-submit {

  display: block;

  width: 100%;

  height: 36px;

  border: none;

  background: #1d84df;

  color: #ffffff;

  margin-bottom: 15px;

}

@media (min-width: 1025px) {

  .frm-single .frm-submit:hover {

    background: #304ffe;

  }

}

.frm-single .frm-submit i {

  margin-left: 5px;

}

.frm-single .frm-footer {

  padding: 20px 0px 0px 0px;

  margin-top: 15px;

  border-top: 1px solid #ccc;

  color: #999;

}

.frm-single .avatar {

  display: block;

  margin: 0px auto 20px;

  float: none;

  position: relative;

  width: 70px;

  height: 70px;

  -webkit-border-radius: 100%;

  -moz-border-radius: 100%;

  border-radius: 100%;

  overflow: hidden;

}

.frm-single .ico-email {

  display: block;

  margin: 0px auto 20px;

  float: none;

  width: 80px;

  height: 80px;

}

.frm-single .txt-login-with {

  text-align: center;

  margin-bottom: 15px;

  color: #999;

}

/*---------------------------------------------------------------------*/

/*-----------------------------------------------------------------------

29. 404,500 Page

*/

#page-404 {

  height: 100%;

  background: url('file:///C|/Users/Public/Documents/12-6-19/kombatlink/assets/images/404.jpg') top center repeat;

  background-position: center center;

  background-attachment: fixed;

  overflow: hidden;

  width: 100%;

  padding: 0px 15px 0px 15px;

}

#page-404 .content {

  width: 100%;

  max-width: 600px;

  display: block;

  margin: 0px auto 0px;

  float: none;

  text-align: center;

  position: relative;

  top: 50%;

  -webkit-transform: translateY(-50%);

  -o-transform: translateY(-50%);

  transform: translateY(-50%);

}

#page-404 p {

  padding: 30px 0px 30px 0px;

  color: #777;

  font-size: 16px;

  line-height: 30px;

  margin: 0px;

}

@media (max-height: 359px) {

  #page-404 {

    min-height: 100%;

    height: auto;

  }

  #page-404 .content {

    transform: translate(0, 0);

    -webkit-transform: translate(0, 0);

    -moz-transform: translate(0, 0);

    -o-transform: translate(0, 0);

    -ms-transform: translate(0, 0);

    top: 0px;

    margin-top: 60px;

    margin-bottom: 60px;

  }

}

.title-on-desktop {

  position: relative;

}

.title-on-desktop .title {

  text-transform: uppercase;

  font-weight: 800;

  font-size: 28px;

  line-height: 40px;

  position: absolute;

  top: 50%;

  left: 50%;

  -webkit-transform: translate(-50%, -50%);

  -o-transform: translate(-50%, -50%);

  transform: translate(-50%, -50%);

  white-space: nowrap;

}

.title-on-mobile {

  display: none;

}

@media (max-width: 649px) {

  .title-on-desktop {

    display: none;

  }

  .title-on-mobile {

    display: block;

  }

}

/*---------------------------------------------------------------------*/

/*-----------------------------------------------------------------------

30. Projects Page

*/

.prj-header {

  position: relative;

  min-height: 64px;

  padding: 0 130px;

  border-bottom: 1px solid #e8e8e8;

}

.prj-header .btn-submit-prj {

  position: absolute;

  top: 10px;

  right: 0px;

}

.prj-header .result-count {

  position: absolute;

  top: 10px;

  left: 0px;

  font-size: 14px;

  line-height: 34px;

}

.prj-header .filters {

  list-style: none;

  padding: 0px;

  font-size: 0px;

  text-align: center;

  margin-bottom: 0px;

}

.prj-header .filters li {

  display: inline-block;

  font-size: 14px;

  line-height: 34px;

  margin: 0px 5px 0px 5px;

}

.prj-header .filters a {

  display: block;

  color: #435966;

  position: relative;

  padding: 10px 10px 20px;

}

.prj-header .filters a:before {

  content: "";

  width: 100%;

  height: 2px;

  position: absolute;

  bottom: -1px;

  left: 0px;

  background: #188ae2;

  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";

  -moz-opacity: 0;

  -khtml-opacity: 0;

  opacity: 0;

  transition: all 0.4s ease;

  -moz-transition: all 0.4s ease;

  -o-transition: all 0.4s ease;

  -ms-transition: all 0.4s ease;

  -webkit-transition: all 0.4s ease;

}

.prj-header .filters a:hover,

.prj-header .filters a.active {

  color: #188ae2;

}

.prj-header .filters a:hover:before,

.prj-header .filters a.active:before {

  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";

  -moz-opacity: 1;

  -khtml-opacity: 1;

  opacity: 1;

}

@media (max-width: 999px) {

  .prj-header {

    padding: 65px 0 0;

  }

}

.prj-item {

  display: block;

  position: relative;

  background: #ffffff;

  border: 1px solid #e9e9e9;

  -webkit-border-radius: 2px;

  -moz-border-radius: 2px;

  border-radius: 2px;

}

.prj-item .top-project-section {

  height: 220px;

  padding: 40px 20px 20px 20px;

  border-bottom: 1px solid #e9e9e9;

}

.prj-item .top-project-section h3 {

  margin: 20px 0px 0px 0px;

  font-size: 16px;

  line-height: 20px;

  color: #3a3a3a;

}

.prj-item .top-project-section .meta {

  font-size: 13px;

  color: #b2b2b2;

}

.prj-item .top-project-section .meta span {

  color: #767676;

}

.prj-item .project-icon {

  height: 90px;

}

.prj-item .project-icon img {

  display: block;

  margin: 0px auto 0px;

  float: none;

  max-width: 80px;

  height: auto;

}

.prj-item .bottom-project-section {

  padding: 15px 20px 15px 20px;

  font-size: 13px;

  color: #767676;

}

.prj-item .bottom-project-section:after {

  content: "";

  display: block;

  width: 0px;

  height: 0px;

  clear: both;

  overflow: hidden;

}

.prj-item .bottom-project-section .points {

  float: left;

  margin-right: 10px;

}

.prj-item .bottom-project-section .views {

  float: left;

  margin-right: 10px;

}

.prj-item .bottom-project-section .feedable-time {

  float: right;

}

/*---------------------------------------------------------------------*/

/*-----------------------------------------------------------------------

31. Task Board

*/

.task-item {

  display: block;

  margin-bottom: 20px;

  padding: 10px 15px 10px 15px;

  border: 1px solid #e9e9e9;

  -webkit-border-radius: 3px;

  -moz-border-radius: 3px;

  border-radius: 3px;

}

.task-item .title {

  font-weight: 600;

  color: #435966;

  transition: all 0.4s ease;

  -moz-transition: all 0.4s ease;

  -o-transition: all 0.4s ease;

  -ms-transition: all 0.4s ease;

  -webkit-transition: all 0.4s ease;

}

@media (min-width: 1025px) {

  .task-item .title:hover {

    color: #188ae2;

  }

}

.task-item .metas {

  padding-top: 5px;

  color: #bbb;

}

.task-item .metas:after {

  content: "";

  display: block;

  width: 0px;

  height: 0px;

  clear: both;

  overflow: hidden;

}

.task-item .metas .meta {

  margin-right: 10px;

  float: left;

}

.task-item .metas .ico {

  font-size: 14px;

  line-height: 22px;

  display: inline-block;

  margin-right: 4px;

}

.task-item .progress {

  margin-bottom: 5px;

  height: 10px;

  margin-top: 10px;

}

.task-item .task-icon {

  font-size: 40px;

  line-height: 50px;

  text-align: center;

  margin-bottom: 5px;

}

.task-user {

  margin-bottom: 20px;

  position: relative;

  top: 0;

  left: 0;

  width: auto;

}

.task-user .name {

  margin-bottom: 5px;

}

.user-assign-list li {

  vertical-align: top;

}

.user-assign-list .avatar {

  display: block;

  width: 34px;

  height: 34px;

  overflow: hidden;

  position: relative;

  -webkit-border-radius: 100%;

  -moz-border-radius: 100%;

  border-radius: 100%;

}

.user-assign-list .add_new {

  -webkit-border-radius: 100%;

  -moz-border-radius: 100%;

  border-radius: 100%;

}

.user-attach-list .thumbs {

  display: block;

  padding: 5px;

  width: 100px;

  border: 1px solid #e9e9e9;

}

.user-attach-list .add_new {

  -webkit-border-radius: 100%;

  -moz-border-radius: 100%;

  border-radius: 100%;

}

.frm-comment {

  position: relative;

  padding: 10px 0px 10px 55px;

}

.frm-comment .avatar {

  position: absolute;

  top: 4px;

  left: 0px;

  width: 44px;

  height: 44px;

  -webkit-border-radius: 100%;

  -moz-border-radius: 100%;

  border-radius: 100%;

  overflow: hidden;

}

/*---------------------------------------------------------------------*/

/*-----------------------------------------------------------------------

32. Profile

*/

.profile-avatar .btn {

  margin: 0px;

  -webkit-border-radius: 0px;

  -moz-border-radius: 0px;

  border-radius: 0px;

  color: #fff;

}

.profile-avatar .btn-friend {

  background: #00aeff;

}

.profile-avatar .btn-inbox {

  background: #1d84df;

}

.profile-friends-list li {

  margin-bottom: 10px;

}

.profile-friends-list li .avatar {

  width: 48px;

  height: 48px;

  -webkit-border-radius: 100%;

  -moz-border-radius: 100%;

  border-radius: 100%;

  overflow: hidden;

  position: relative;

  display: block;

}

.dot-list {

  list-style: none;

  padding: 0px;

  margin: 0px;

}

.dot-list li {

  margin-bottom: 15px;

  padding: 0px 0px 0px 30px;

  position: relative;

}

.dot-list li:before {

  content: "\f10c";

  font-family: 'FontAwesome';

  position: absolute;

  top: 0px;

  left: 0px;

  font-size: 14px;

  line-height: 20px;

}

.dot-list .date {

  font-size: 12px;

  color: #939ba2;

  display: block;

}

/*---------------------------------------------------------------------*/

/*-----------------------------------------------------------------------

33. Contact List

*/

.box-contact {

  position: relative;

  margin-top: 60px;

  padding: 60px 20px 20px 20px;

  border: 1px solid #e0e0e0;

  background: #ffffff;

  -webkit-border-radius: 8px;

  -moz-border-radius: 8px;

  border-radius: 8px;

  text-align: center;

  margin-bottom: 30px;

}

.box-contact .avatar {

  width: 108px;

  height: 108px;

  position: absolute;

  top: -54px;

  border: 4px solid #e7e6e6;

  -webkit-border-radius: 100%;

  -moz-border-radius: 100%;

  border-radius: 100%;

  left: 50%;

  -webkit-transform: translateX(-50%);

  -o-transform: translateX(-50%);

  transform: translateX(-50%);

}

.box-contact .name {

  font-size: 24px;

  margin-bottom: 0px;

}

.box-contact .job {

  font-size: 16px;

  color: #a8a8a8;

  font-weight: 700;

  padding-bottom: 15px;

  position: relative;

  margin-bottom: 20px;

}

.box-contact .job:before {

  content: "";

  position: absolute;

  bottom: 0px;

  left: 50%;

  -webkit-transform: translateX(-50%);

  -o-transform: translateX(-50%);

  transform: translateX(-50%);

  width: 75px;

  height: 2px;

  background: #fbbc05;

}

.box-contact p {

  font-size: 14px;

  line-height: 22px;

}

.contact-social a {

  display: block;

  font-size: 20px;

  line-height: 40px;

  width: 40px;

  height: 40px;

  -webkit-border-radius: 100%;

  -moz-border-radius: 100%;

  border-radius: 100%;

  color: #fff;

}

.contact-social .fa-phone {

  background: #00aeff;

}

.contact-social .fa-envelope {

  background: #00bf4f;

}

.contact-social .fa-facebook {

  background: #3b5999;

}

.contact-social .fa-twitter {

  background: #55acee;

}

.contact-social .fa-google-plus {

  background: #dd4b39;

}

/*---------------------------------------------------------------------*/

/*-----------------------------------------------------------------------

34. Pricing Plan

*/

.pricing-table {

  display: table;

  margin-top: 30px;

  width: 100%;

  text-align: center;

}

.pricing-table:after {

  content: "";

  display: block;

  width: 0px;

  height: 0px;

  clear: both;

  overflow: hidden;

}

.pricing-table .pricing-table-row {

  display: table-row;

}

.pricing-table .col {

  display: table-cell;

  position: relative;

  width: 20%;

  vertical-align: top;

}

.pricing-table .col:before {

  content: "";

  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";

  -moz-opacity: 0;

  -khtml-opacity: 0;

  opacity: 0;

  position: absolute;

  top: -7px;

  left: 0px;

  width: 100%;

  height: 7px;

  background: #ffcd03;

  transition: all 0.4s ease;

  -moz-transition: all 0.4s ease;

  -o-transition: all 0.4s ease;

  -ms-transition: all 0.4s ease;

  -webkit-transition: all 0.4s ease;

}

.pricing-table .col-first:before {

  display: none;

}

.pricing-table .col-first .thead {

  border: 1px solid #e9eaeb;

  background: none;

  color: #304ffe;

  text-transform: uppercase;

  font-size: 18px;

  font-weight: 700;

  background: #fff;

}

.pricing-table .col-first .td {

  border-left: 1px solid #e9eaeb;

}

.pricing-table .thead {

  height: 110px;

  padding: 13px;

  background: #1d84df;

  color: #fff;

  vertical-align: middle;

}

.pricing-table .thead h4 {

  margin: 0px;

  font-weight: 700;

  text-transform: uppercase;

  line-height: 34px;

  white-space: nowrap;

}

.pricing-table .thead.bg-main-2 {

  background-color: #1d84df;

}

.pricing-table .thead.bg-blue-1 {

  background-color: #2444f9;

}

.pricing-table .thead.bg-blue-2 {

  background-color: #1e3def;

}

.pricing-table .thead.bg-blue-3 {

  background-color: #1d84df;

}

.pricing-table .price {

  display: inline-block;

  position: relative;

  padding: 0px 45px 0px 15px;

}

.pricing-table .price .number {

  font-size: 45px;

  line-height: 50px;

  font-weight: 700;

}

.pricing-table .price .currency {

  position: absolute;

  top: 0px;

  left: 0px;

  font-size: 18px;

  line-height: 50px;

  font-weight: 700;

}

.pricing-table .price .small_number {

  position: absolute;

  right: 0px;

  top: 5px;

  width: 40px;

  text-align: left;

  font-size: 18px;

}

.pricing-table .price .time {

  position: absolute;

  top: 30px;

  right: 0px;

  width: 40px;

  text-align: left;

  font-size: 16px;

  color: #fcd20b;

  font-weight: 700;

}

.pricing-table .td {

  left: 24px;

  height: 62px;

  padding: 7px;

  border-right: 1px solid #e9eaeb;

  border-bottom: 1px solid #e9eaeb;

  font-size: 14px;

  line-height: 48px;

  color: #222222;

  white-space: nowrap;

  overflow: hidden;

  background: #fff;

}

.pricing-table .td:last-child {

  height: 100px;

}

.pricing-table .td .fa-times {

  color: #666666;

  font-size: 24px;

  line-height: 48px;

}

.pricing-table .td .fa-check {

  color: #1d84df;

  font-size: 24px;

  line-height: 48px;

}

.pricing-table .td .btn-order {

  display: inline-block;

  width: 125px;

  color: #fff;

  background: #212121;

  font-size: 12px;

  line-height: 40px;

  font-weight: 700;

  margin: 23px 0px 23px 0px;

}

.pricing-table .center-v {

  top: 50%;

  -webkit-transform: translateY(-50%);

  -o-transform: translateY(-50%);

  transform: translateY(-50%);

  position: relative;

}

.pricing-table .col-featured {

  top: -10px;

  -webkit-box-shadow: 0px 1px 12px 0px rgba(34, 34, 34, 0.25);

  -moz-box-shadow: 0px 1px 12px 0px rgba(34, 34, 34, 0.25);

  box-shadow: 0px 1px 12px 0px rgba(34, 34, 34, 0.25);

}

.pricing-table .col-featured .thead {

  height: 120px;

  padding-top: 23px;

}

.pricing-table .col-featured .td:last-child {

  height: 110px;

}

.pricing-table .col-featured .td .btn-order {

  background: #1d84df;

}

@media (min-width: 1025px) {

  .pricing-table .td .btn-order:hover {

    background: #1d84df;

  }

  .pricing-table .col:hover:before {

    opacity: 1;

  }

}

@media (max-width: 1199px) {

  .pricing-table .thead h4 {

    font-size: 14px;

  }

}

@media (max-width: 991px) {

  .pricing-table .col-first {

    display: none!important;

  }

  .pricing-table .col:nth-child(2) .td {

    border-left: 1px solid #E9EAEB;

  }

}

@media (max-width: 991px) {

  .pricing-table .col {

    width: 100%;

    display: block;

    margin: 0 auto 50px;

    max-width: 300px;

  }

  .pricing-table .col:last-child {

    margin-bottom: 0;

  }

  .pricing-table .col .td {

    border-left: 1px solid #E9EAEB;

  }

  .pricing-table .thead h4 {

    font-size: 18px;

  }

}

/*---------------------------------------------------------------------*/

/*-----------------------------------------------------------------------

35. Invoice

*/

.invoice-box {

  max-width: 800px;

  margin: auto;

  padding: 30px;

  border: 1px solid #eee;

  font-size: 16px;

  line-height: 24px;

  color: #555;

  background: #ffffff;

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);

  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);

  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);

}

.invoice-box .logo {

  color: #435966;

  font-weight: 600;

}

.invoice-box .logo span {

  font-weight: 400;

}

.invoice-box table {

  width: 100%;

  text-align: left;

}

.invoice-box table td {

  padding: 5px;

  vertical-align: top;

}

.invoice-box table tr td:nth-child(2) {

  text-align: right;

}

.invoice-box table tr.top table td {

  padding-bottom: 20px;

}

.invoice-box table tr.top table td.title {

  font-size: 45px;

  line-height: 45px;

  color: #333;

}

.invoice-box table tr.information table td {

  padding-bottom: 40px;

}

.invoice-box table tr.heading td {

  background: #eee;

  border-bottom: 1px solid #ddd;

  font-weight: 700;

}

.invoice-box table tr.details td {

  padding-bottom: 20px;

}

.invoice-box table tr.item td {

  border-bottom: 1px solid #eee;

}

.invoice-box table tr.item.last td {

  border-bottom: none;

}

.invoice-box table tr.total td:nth-child(2) {

  border-top: 2px solid #eee;

  font-weight: 700;

}

@media only screen and (max-width: 600px) {

  .invoice-box table tr.information table td,

  .invoice-box table tr.top table td {

    width: 100%;

    display: block;

    text-align: center;

  }

}

/*---------------------------------------------------------------------*/

/*-----------------------------------------------------------------------

36. Gallery

*/

.item-gallery {

  display: block;

  position: relative;

  margin-bottom: 15px;

  border: 10px solid #ffffff;

  background: #222;

  -webkit-box-shadow: 0px 0px 5px 0px rgba(14, 14, 14, 0.1);

  -moz-box-shadow: 0px 0px 5px 0px rgba(14, 14, 14, 0.1);

  box-shadow: 0px 0px 5px 0px rgba(14, 14, 14, 0.1);

}

.item-gallery:before {

  content: "\f065";

  font-family: 'FontAwesome';

  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";

  -moz-opacity: 0;

  -khtml-opacity: 0;

  opacity: 0;

  font-size: 16px;

  line-height: 22px;

  position: absolute;

  left: 10px;

  bottom: 5px;

  color: #a5a5a5;

  z-index: 10;

  transition: all 0.4s ease;

  -moz-transition: all 0.4s ease;

  -o-transition: all 0.4s ease;

  -ms-transition: all 0.4s ease;

  -webkit-transition: all 0.4s ease;

}

.item-gallery img {

  min-width: 100%;

  transition: all 0.4s ease;

  -moz-transition: all 0.4s ease;

  -o-transition: all 0.4s ease;

  -ms-transition: all 0.4s ease;

  -webkit-transition: all 0.4s ease;

}

.item-gallery .title {

  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";

  -moz-opacity: 0;

  -khtml-opacity: 0;

  opacity: 0;

  position: absolute;

  top: 50%;

  left: 0px;

  z-index: 20;

  width: 100%;

  margin: 0px;

  text-align: center;

  color: #ffffff;

  font-size: 18px;

  line-height: 28px;

  font-weight: 500;

  transform: translate(0, -40%);

  -webkit-transform: translate(0, -40%);

  -moz-transform: translate(0, -40%);

  -o-transform: translate(0, -40%);

  -ms-transform: translate(0, -40%);

  transition: all 0.4s ease;

  -moz-transition: all 0.4s ease;

  -o-transition: all 0.4s ease;

  -ms-transition: all 0.4s ease;

  -webkit-transition: all 0.4s ease;

}

@media (min-width: 1025px) {

  .item-gallery:hover:before {

    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";

    -moz-opacity: 1;

    -khtml-opacity: 1;

    opacity: 1;

  }

  .item-gallery:hover .title {

    transform: translate(0, -50%);

    -webkit-transform: translate(0, -50%);

    -moz-transform: translate(0, -50%);

    -o-transform: translate(0, -50%);

    -ms-transform: translate(0, -50%);

    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";

    -moz-opacity: 1;

    -khtml-opacity: 1;

    opacity: 1;

  }

  .item-gallery:hover img {

    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=35)";

    -moz-opacity: 0.35;

    -khtml-opacity: 0.35;

    opacity: 0.35;

  }

}

/* .item-gallery */

.isotope-filter .filter-controls {

  padding-top: 8px;

  margin-bottom: 43px;

  font-size: 0px;

  padding-left: 0px;

  text-align: center;

}

.isotope-filter .filter-controls li {

  display: inline-block;

  padding: 10px 15px 0px 15px;

}

.isotope-filter .filter-controls a {

  display: block;

  color: #435966;

  font-size: 16px;

  line-height: 26px;

  font-weight: 500;

}

@media (min-width: 1025px) {

  .isotope-filter .filter-controls a:hover {

    color: #1d84df;

  }

}

.isotope-filter .filter-controls .js__active {

  color: #1d84df;

}

/*---------------------------------------------------------------------*/

/*-----------------------------------------------------------------------

37. Modal

*/

.modal-content {

  padding: 0 15px;

  box-shadow: none;

  -moz-box-shadow: none;

  -webkit-box-shadow: none;

  border-radius: 0;

  -webkit-border-radius: 0;

  -moz-border-radius: 0;

}

.modal-dialog {

  max-width: 100%;

}

/*---------------------------------------------------------------------*/

/*-----------------------------------------------------------------------

38. Form Control

*/

.form-control {

  line-height: 24px;

  padding: 9px 14px;

  height: 45px;

  border-color: #ccd1d9;

  box-shadow: none;

  -webkit-box-shadow: none;

  -moz-box-shadow: none;


}

.form-control.input-sm {

  padding: 0 10px;

  height: 30px;

}

.form-control:focus {

  border-color: #00aeff !important;

  -webkit-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.12) !important;

  -moz-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.12) !important;

  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.12) !important;

}

textarea.form-control {

  height: 105px;

}

.has-success .form-control {

  border-color: #00bf4f;

}

.has-warning .form-control {

  border-color: #fbbc05;

}

.has-error .form-control {

  border-color: #ea4335;

}

.has-inverse .form-control {

  border-color: #435966;

}

.form-with-icon {

  position: relative;

}

.form-with-icon .item-icon-right {

  position: absolute;

  right: 0;

  top: 50%;

  width: 50px;

  text-align: center;

  font-size: 20px;

  line-height: 30px;

  transform: translateY(-50%);

  -moz-transform: translateY(-50%);

  -webkit-transform: translateY(-50%);

}

.form-with-icon .form-control {

  padding-right: 50px;

}

.has-success .form-with-icon .item-icon {

  color: #00bf4f;

}

.has-warning .form-with-icon .item-icon {

  color: #fbbc05;

}

.has-error .form-with-icon .item-icon {

  color: #ea4335;

}

.has-inverse .form-with-icon .item-icon {

  color: #435966;

}

.input-group-btn > .btn {

  min-width: 50px;

  padding: 7px 10px;

  font-size: 18px;

  line-height: 29px;

  color: #656d78;

  border: 1px solid #ccd1d9;

  box-shadow: none;

  -webkit-box-shadow: none;

  -moz-box-shadow: none;

  height: 45px;

}

.input-group-btn > .btn.no-border {

  border: none;

  line-height: 31px;

}

.input-group-btn > .btn span {

  font-size: 14px;

}

/*---------------------------------------------------------------------*/

/*-----------------------------------------------------------------------

39. Reviews Widget

*/

.review-list {

  color: #adadad;

  font-size: 14px;

  line-height: 24px;

}

.review-item {

  margin-bottom: 15px;

  padding-bottom: 15px;

  border-bottom: 1px solid #eee;

}

.review-item .top {

  padding-bottom: 5px;

}

.review-item .top:after {

  content: '';

  clear: both;

  display: block;

}

.review-item .top .name {

  display: inline-block;

  margin-right: 10px;

  color: #435966;

}

.review-item .top .date {

  display: inline-block;

}

.review-item .top .star-rating {

  float: right;

  white-space: nowrap;

}

@media (max-width: 1299px) and (min-width: 1199px) {

  .review-item .top .star-rating {

    width: 100%;

  }

}

.review-item .desc {

  font-size: 13px;

  line-height: 23px;

}

/*---------------------------------------------------------------------*/

/*-----------------------------------------------------------------------

40. Activity Widget

*/

.activity-list {

  color: #435966;

  margin-bottom: 20px;

}

.activity-item {

  position: relative;

  padding-left: 35px;

  padding-bottom: 20px;

  font-size: 14px;

  line-height: 24px;

}

.activity-item .bar {

  position: absolute;

  top: 0;

  bottom: 0;

  left: 10px;

  width: 1px;

}

.activity-item .dot {

  position: absolute;

  top: 0;

  left: -4px;

  width: 9px;

  height: 9px;

  border-radius: 50%;

  -webkit-border-radius: 50%;

  -moz-border-radius: 50%;

}

.activity-item .last-dot {

  position: absolute;

  bottom: 0;

  left: -2px;

  width: 5px;

  height: 5px;

  border-radius: 50%;

  -webkit-border-radius: 50%;

  -moz-border-radius: 50%;

}

.activity-item .date {

  font-size: 12px;

  color: #adadad;

}

.activity-link {

  display: block;

  padding: 8px 10px 5px;

  text-align: center;

  color: #435966;

}

/*---------------------------------------------------------------------*/

/*-----------------------------------------------------------------------

41. Todo Widget

*/

.todo-list {

  margin-bottom: 15px;

}

.todo-item {

  padding: 15px 0;

  border-bottom: 1px solid #eee;

}

.todo-item .checkbox {

  margin: 0;

}

.todo-item .checkbox input:checked + label {

  color: #adadad;

  text-decoration: line-through;

}

.todo-form {

  padding-top: 10px;

}

/*---------------------------------------------------------------------*/

/*-----------------------------------------------------------------------

42. X-Editable

*/

.editableform select.form-control,

.editableform input.form-control {

  padding: 4px 10px;

  height: 32px;

}

.editableform .btn-sm {

  height: 32px;

  padding: 0 20px;

  line-height: 32px;

}

/*---------------------------------------------------------------------*/

/*-----------------------------------------------------------------------

43. Sparkline Chart

*/

.jqstooltip {

  -webkit-box-sizing: content-box;

  -moz-box-sizing: content-box;

  box-sizing: content-box;

}

/*---------------------------------------------------------------------*/

/*-----------------------------------------------------------------------

44. SweetAlert

*/

.sweet-alert .sa-button-container button {

  background: #e4e7ea;

  outline: none;

  font-size: 16px;

  line-height: 26px;

  padding: 8px 25px;

  border: none;

  font-weight: 500;

  font-family: "Poppins";

  box-shadow: none!important;

  -moz-box-shadow: none!important;

  -webkit-box-shadow: none!important;

  border-radius: 4px;

  -webkit-border-radius: 4px;

  -moz-border-radius: 4px;

}

.sweet-alert .sa-button-container button.cancel {

  background: #e4e7ea;

  color: #435966;

}

.sweet-alert .sa-button-container button.cancel:hover {

  background: #e4e7ea;

}

.sweet-alert .sa-button-container button.confirm {

  background: #ea4335;

}

.sweet-alert.visible .sa-icon.sa-success .sa-line {

  background: #00bf4f;

}

.sweet-alert.visible .sa-icon.sa-warning {

  border-color: #fbbc05;

}

.sweet-alert.visible .sa-icon.sa-error {

  border-color: #ea4335;

}

.sweet-alert.visible .sa-icon.sa-error .sa-line {

  background: #ea4335;

}

/*--------------------------------------------------------=---*/

/*===========================================================*/

/*-------------------my style start-------------------------*/

/*=========================================================*/

/*==========================================================*/

/*Sales man Css Homepage start*/
.main_menu_abydos, .abydos_header .logo {
  /*background: linear-gradient(to bottom, rgba(0,0,0,0.7) 0%,rgba(0,0,0,0.7) 100%) , url('../images/DCM%20Bombs%20-banner.png');
  border-radius: 0 0px 0px 0;
  color: #fff;

  /*background: linear-gradient(to bottom, #ee7d32, #ff5f49) url('../images/Admin_login.png');*/
    /* background-image: linear-gradient(to bottom, rgba(255, 69, 0, 0.5), #ed422b), url('../images/side_bar.png'); */
	  /*background-image: linear-gradient(to bottom, rgba(238, 125, 50, 0), #ff5f49);*/
	    background-image: linear-gradient(to bottom, #ee7d32, #ff5f49);
}
.main_menu_abydos .content{
  background-image: url("../images/side_bar.png");
  background-repeat: no-repeat;
}


.salesman_header .logo{
  margin: 20px 0 0;
}


.sales_searchbox input {
  padding: 10px 15px 10px 40px;
  border: 0;
  width: 300px;
  border-radius: 21px;
  color: #9597ab;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  margin: 0 0 20px;
  border: 1px transparent;
}

.sales_searchbox input:focus {
  border: 1px solid #ccc;
}

.sales_searchbox svg , .sales_searchbox i{
  position: absolute;
  margin: 13px 0 0 15px;
  font-size: 16px;
  fill: #9597ab;
}

/*user accounts*/

/*3 dots*/
/*transection*/
/*purchase table last column 3dots setting*/
.salesman_user .dropdown {
  position: relative;
  display: inline-block;
  float: right;
}
.salesman_user .dropdown button{
  background: transparent;
  border: 0;
}
.salesman_user .dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.2);
  z-index: 1;
  padding: 10px 0 10px 0;
}

.salesman_user .dropdown-content::before {
  position: absolute;
  content: '';
  width: 20px;
  height: 20px;
  border-left: 1px solid #eeece5;
  border-top: 1px solid #eeece5;
  top: 0;
  right: 10%;
  transform: rotate(45deg);
  margin-top: -10px;
  background: #fff;
}

.salesman_user .dropdown-content a {
  color: black;
  padding: 15px 15px;
  text-decoration: none;
  display: inline-flex;
  text-align: left;
  font-size: 15px;
}
.salesman_user .dropdown-content a:hover {
  color: #ccc !important;
}
.salesman_user .dropdown-content a svg , .salesman_user .dropdown-content a i {
  vertical-align: middle;
  margin: 0px 10px 0 0;
  font-size: 20px;
  border: 1px solid #000;
  padding: 2px;
  border-radius: 50px;
}
.salesman_user .dropdown:hover .dropdown-content {display: block; margin: 10px 0 0 -75px}

.name .dropbtn a i{
  vertical-align: middle;
}

.account_icon_outer{
  float: left;
  background: #212945;
  height: 45px;
  width: 45px;
  border-radius: 50px;
  line-height: 45px;
  margin: -1px 10px 0 0;
}

.account_icon{
  /*background: #212945;*/
  padding: 12px;
  border-radius: 50px;
  font-size: 15px;
  margin: 0 11px 0 0;
  color: #fff;
}

.salesman_user .name{
  padding: 1px 20px 1px 0;
  background-color: #fff;
  border-radius: 50px;
  
}

.salesman_user .name a{   
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.07;
  letter-spacing: normal;
  color: #3d4060;
}




/*Content start*/

.welcome-admin-heading h4{
  margin: 0 0 30px 0;
  font-family: 'Poppins', sans-serif;
  font-size: 39px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}

.sales_top_outer{
  margin: 0 0 30px 0;
}

.common_div{
  background: #3a26d0;
  color: #fff;
  margin: 0 20px 20px 0;
  padding:30px 20px 10px;
  border-radius: 19px;
  width: 100%;
  min-height: 150px;
}

.col_mit{
  box-shadow: 3px 5px 12px 0 rgba(252, 88, 75, 0.61);
  background-image: linear-gradient(to left, #fb4d5f, #fc584b 0%);
}

.col_call{
  box-shadow: 3px 5px 12px 0 rgba(85, 141, 255, 0.72);

  background-image: linear-gradient(to left, #434dff, #63beff 0%);
}

.col_email{
  box-shadow: 3px 5px 12px 0 rgba(236, 131, 232, 0.76);

  background-image: linear-gradient(to left, #f287e6, #c771f5 0%);
}

.col_track{
  box-shadow: 3px 5px 12px 0 rgba(248, 139, 27, 0.77);

  background-image: linear-gradient(to left, #f57d00, #fca54b 0%);
}

.common_div h5{
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.05;
  letter-spacing: normal;
  color: var(--white);
}

.common_div h5 svg , .common_div h5 i{
  vertical-align: middle;
  font-size: 30px;
  margin: 0 0px 0 0;
  padding: 10px;
  border-radius: 50%;
}

.common_div p{
  font-family: 'Poppins', sans-serif;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.24;
  letter-spacing: normal;
  color: var(--white);
}

.common_div p{
  text-align: right;
}

.common_div p svg ,.common_div p i{
  vertical-align: middle;
  margin: 0 0 0 6px;
}

/*Graph section start*/
.graphs_content_row{
  margin: 30px 0 0 0;
}

.home_graph1_outer , .home_right_box1_outer{
  background: #fff;
  padding: 30px;
  border-radius: 19px;
  margin: 0px 0 30px;
  border: 1px solid #f6f3f3;
}

.home_graph1_outer h3, .home_right_box1_outer h3{
  font-family: 'Poppins', sans-serif;
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  margin: 0 0 30px;
}
/*Row 1 start*/
.home_row1_box{
  padding: 30px 30px;
}



/*Row 2*/
.home_row2_commonbox {
  min-height: 380px;
}

.home_row2_commonbox h3{
  font-size: 20px;
  float: left;
}

.home_row2_box2 h3{
  float: none;
  margin: 0 0 15px;
}


/*Select option arrow css*/
.selectdiv_common {
  position: relative;
  /*Don't really need this just for demo styling*/
  /*
  float: left;
  min-width: 200px;
  margin: 50px 33%;*/
}

/* IE11 hide native button (thanks Matt!) */
select::-ms-expand {
display: none;
}

.selectdiv_common:after {
  content: '>';
  font: 17px "Consolas", monospace;
  color: #333;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  right: 18px;
  /*Adjust for position however you want*/
  
  top: 5px;
  padding: 0 0 2px;
  /*border-bottom: 1px solid #999;*/
  /*left line */
  
  position: absolute;
  pointer-events: none;
}

.selectdiv_common select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Add some styling */
  
  display: block;
  width: 100%;
  max-width: 175px;
  height: 50px;
  float: right;
  margin: -10px 0px 25px;
  padding: 0px 24px;
  font-size: 16px;
  line-height: 1.75;
  color: #333;
  background-color: #ffffff;
  background-image: none;
  border: 0px solid #cccccc;
  -ms-word-break: normal;
  word-break: normal;
}

.selectdiv_common .form-control:focus {
  border-color: #00aeff !important;
  -webkit-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.12) !important;
  -moz-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.12) !important;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.12) !important;
}





.col_padding_0{
  pad: 0;
}

.selectdiv2 select{
  max-width: 220px !important;
  padding: 0 !important;
  font-size: 14px;
}

.selectdiv3 select{
  max-width: 100px !important;
  padding: 0 !important;
  font-size: 14px;
}






















/*Row 3 boxes*/

.home_row3_commonbox {

}
.home_row3_commonbox h3{
  font-size: 16px;
  margin: 0;
}


.home_row3_commonbox  canvas{
  margin: 0 0 15px 25%;
}

.home_row3_commonbox button{
  border-radius: 4px;
  padding: 7px 20px 7px 20px;
  text-align: center;
  margin: 0 auto;
  display: block;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  background-color: #fff;
}

/*button 1*/
.home_row3_box1 button{
  border: solid 1px #fb4d5f;
  color: #fb4d5f;
}

.home_row3_box1 button:hover{
  border: solid 1px #000;
  color: #000;
}

/*button 2*/
.home_row3_box2 button ,.home_row3_box3 button{
  border: solid 1px #3d4060;
  color: #3d4060;
}

.home_row3_box2 button:hover , .home_row3_box3 button:hover{
  border: solid 1px #fb4d5f;
  color: #fb4d5f;
}



/*right section start*/

.home_right_box1_outer h3{
  float: left;
}

.home_right_box1_outer h4{
  font-family: 'Poppins', sans-serif;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  margin: 15px 0 0;
}

.home_right_box1_outer button{
  font-family: 'Poppins', sans-serif;
  border-radius: 4px;
  background-color: transparent;
  border: solid 1px #3d4060;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: #3d4060;
  padding: 4px 20px;
  float:right;
}

.home_right_box1_outer button:hover{
  background-color: transparent;
  border: solid 1px #ccc;
  color: #ccc;
}

.home_right_box1_outer img{
  width: 100%;
}

.home_right_box1_outer h4 span{
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #a5a6ac;
  display: block;
}


/*News And Updates*/
.home_news_update_outer{
  clear: both;
  margin: 0 0 15px;
}

.news_thumb{
  width: 20%;
  float: left;
}

.news_thumb img{
  height: 63px;
  width: 63px;
}

.news_text{
  width: 80%;
  display: grid
}

.news_text p{
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.62;
  letter-spacing: normal;
  color: #535359;
}

.news_text p a{
  color: #fb4d5f;
}

.news_text span{
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #8f909e;
  margin: 0 0 10px;
}

.news_text span svg , .news_text span i{
  vertical-align: middle;
  margin: 0 5px 0 0;
  font-size: 15px;
}


















/*==========================Home Page css close===========================*/

/*=========================Inner Page css start===========================*/
