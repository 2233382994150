@media (min-width: 320px) and (max-width: 480px) {

    /*Top Bar start*/
    .my_fixnavbar {
        padding: 20px 20px 20px 40px;
        height: 75px;
    }

    .top_bar{
        /*width: 100%;
        text-align: center;*/
        padding: 8px 10px;
        margin: -4px 0 0 0;
    }

    .pull-left button{
        font-size: 30px;
        margin: 0px 0 0 0px;
    }

    .top_bar p {
        font-size: 15px;
    }

    .top_bar p svg {
        font-size: 20px;
    }

    .topbar_right{
        width: 100%;
        text-align: center;
        margin: 5px 0 0 0;
        display: none;
    }

    /*Setting Icons setting*/
    .setting_outer{
        display: block !important;
        margin: -30px 10px 0 14px;
    }

    .setting_outer .dash_settings  svg{
        vertical-align: middle;
        fill: #3b3b3f;
        font-size: 20px;
        margin-right: 10px;
    }

    /*Dropdown Content setting*/
    .settings_content a{
        font-size: 17px;
    }

    .settings_content a svg{
        vertical-align: middle;
        font-size: 17px;
        margin: 0 10px 0 0;
    }

    .settings_content a:hover{
        color:#ee7d32;
    }










    /*Top Bar close*/
    /*============*/
    /*Summary Page start*/
    .summary_page_content_outer {
        padding: 60px 0px;
    }

    .card_graph select {
        width: 100%;
        margin: 0 0 50px;
    }

    /*summary right garph*/
    .card_graph_right {
        padding: 80px 30px 40px;
        min-height: auto;
    }

    .summary_right_graph canvas{
        height: 180px !important;
        width: 180px !important
    }

    .summary_right_graph input{
        margin-top: 45px !important;
        margin-left: -160px !important;
    }

    /*Summary Page close*/
    /*==================*/
    /*Login Page start*/
    .login_wrapper {
        min-height: auto;
    }

    .login_outer {
        padding: 50px 15px 80px;
        margin: 0 10% !important;
    }

    .close_button svg {
        margin: -30px 0 0 -60px;
        position:unset;
    }


    /*Login Page close*/
    /*==================*/
    /*Search Page start*/

    .search_page_content_outer {
        padding: 20px 0;
    }
    .inner-or p {
        margin: 20px 0px;
    }
    .search_left {
        padding: 50px 15px;
    }

    .search_left  .custom-control-inline {
        width: 100%;
        margin: 0 0px 0px;
    }

    /*Search Right section*/
    .search_right {
        padding: 0 0;
        margin: 50px 0 0 0;
    }

    .search_right ul li svg {
        margin: -4px 10px 0 0;
        font-size: 30px;
    }
    .search_tip h4::after {
        margin: -10px 0px 0px 100px;
    }
    
    .plus_btn_outer{
        margin: 10px 0px 0px 0px !important;
    }
}/*Mobile device max-width 480px; */

/*====================================================================================================================*/

/*=======================================MEdia Query 2 start max-width 767px=========================================*/

@media (min-width: 481px) and (max-width: 767px) {

    /*Top Bar start*/
    .my_fixnavbar {
        padding: 20px 20px 20px 40px;
        height: 100px;
    }

    .top_bar{
        width: 100%;
        text-align: center;
    }
    .top_bar p {
        font-size: 18px;
    }

    .top_bar p svg {
        font-size: 22px;
    }

    .topbar_right{
        width: 100%;
        text-align: center;
        margin: 5px 0 0 0;
    }

    .pull-left button{
        font-size: 24px;
        margin: 25px 0 0 30px;
    }

    /*Top Bar close*/
    /*============*/
    /*Summary Page start*/

    .summary_page_content_outer {
        padding: 60px 0;
    }

    /*Summary Page close*/
    /*==================*/
    /*Login Page start*/
    .login_outer {
        padding: 50px 15px 80px;
        margin: 0 10%;
    }

    /*Login Page close*/
    /*==================*/
    /*Search Page start*/

    .search_page_content_outer {
        padding: 60px 15px;
    }
    .inner-or p {
        margin: 20px 0px;
    }
    .search_left {
        padding: 50px 15px;
    }

    .search_left  .custom-control-inline {
        width: 90%;
        margin: 0px 15px;
    }

    /*browse files button*/
    .browse_input button {
        margin: 20px 0 0 0;
        display: block;
    }


    /*Search right section*/
    .search_right {
        padding: 0 0px;
        margin: 50px 0 0 0;
    }



}/*Tab device max-width 767px; */
/*====================================================================================================================*/
/*=======================================MEdia Query 3 start max-width 991px=========================================*/
@media (min-width: 768px) and (max-width: 991px) {

    
    /*Search page start*/
    .search_page_content_outer {
        padding: 60px 15px;
    }

    .search_left {
        padding: 50px 15px;
    }

    .search_left  .custom-control-inline {
        width: 90%;
        margin: 0 15px;
    }

    /*search right section start*/
    .search_right {
        padding: 0 0px;
        margin: 50px 0 0 0;
    }

    .pull-left button{
        font-size: 30px;
        margin: 10px 0 0 0;
    }

    /*search page*/
    .inner-or p {
        margin: 30px 0px;
    }


}
/*==================================================================================================================*/
/*=======================================MEdia Query 3 start max-width 1200px=========================================*/
@media (min-width: 992px) and (max-width: 1200px) {

    /*Search page start*/
    .search_page_content_outer {
        padding: 60px 15px;
    }

    .search_left {
        padding: 50px 0;
    }

    .search_left  .custom-control-inline {
     
        margin: 0 0px;
    }

    /*search right section*/
    .search_right {
        padding: 0 0px;
    }

    .search_right ul li svg {
        font-size: 50px;
    }

    /*search page*/
    .inner-or p {
        margin: 30px 0px;
    }

    /*exclusive critearea outer*/

    .exclusion_criteria_outer{
        padding: 0 15px;    
    }

    .select_text_box p {
        font-size: 14px;
    }
    .select_text_box p a svg{
        font-size: 18px;
    }




}


/*==================================================================================================================*/
/*=======================================MEdia Query 3 start max-width 1400px=========================================*/
@media (min-width: 1201px) and (max-width: 1400px) {

    /*Summary page start*/
    .summary_page_content_outer {
        padding: 20px 0;
    }

    .card_graph {
        padding: 30px 0;
        margin: 0 0 30px;
    }

    /*Search page start*/
    .search_page_content_outer {
        padding: 20px 15px;
    }

    .search_left {
        padding: 50px 30px;
    }

    .search_left .custom-control-inline {
        margin: 0 px 0 30px;
    }

    .search_left .custom-control-inline {
     
        padding: 30px 15px 15px;
        min-height: 275px;
    }




    .browse_file {
        padding: 20px 10px;
    }

    .browse_input span {
        font-size: 14px;
    }







    /*Search tops start*/
    .search_right ul li svg {
        font-size: 30px;
        margin: -5px 10px 0 0;
    }
    

}


/*==================================================================================================================*/
/*=======================================MEdia Query 3 start max-width 1400px=========================================*/
@media (min-width: 1401px) and (max-width: 1600px) {

    /*Summary page start*/
    .summary_page_content_outer {
        padding: 60px 0;
    }

    .card_graph {
        padding: 30px 0;
        margin: 0 0 30px;
    }

    /*Search page start*/
    .search_page_content_outer {
        padding: 60px 15px;
    }

    .search_left {
        padding: 50px 30px;
    }

    .search_left .custom-control-inline {
        margin: 0 45px 0 0px;
    }

    /*Search tops start*/
    .search_right ul li svg {
        font-size: 45px;
    }
    

}


/*=======================================MEdia Query 3 start max-width 1600px=========================================*/
/*only for icon hidess*/

@media (min-width: 800px) and (max-width: 3000px) {

    .menu-mobile-button {
        display: none;
    }



}