body {
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    font-family: "Source Sans Pro", sans-serif;
}
/*=====================*/

/*========================*/

/*same class*/
.box_content_outer {
    border-radius: 7px;
    box-shadow: 5px 5px 13px 0 #e1e1e1;
    background-color: #ffff;
    padding: 20px 40px;
    margin: 15px 0px 30px 0px;
}
.box_content_outer h2 {
    font-size: 32px;
    font-weight: 600;
    color: #252524;
    margin: 10px 0px;
}

/*same class*/
.abydos_side_bar_outer {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 30;
    width: 300px;
    height: 100%;
    background-image: linear-gradient(to bottom, #ee7d32, #ff5f49);
    color: #fff;
    text-align: center;
}
/*body section outer*/
.body_section_outer {
    background-color: #f3f3f3;
    position: relative;
    z-index: 10;
    margin-left: 300px;
    min-height: 100vh;
}
.top_bar {
    padding: 8px 25px;
    width: 100%;
}
.cloud_icon {
    float: left;
    font-size: 40px;
    color: #ff5f49;
    margin: 0px 15px 0px 0px;
}
.top_bar_content {
    float: left;
}
.top_bar_content p {
    font-size: 20px;
    color: #28282c;
    margin-top: 10px;
}
.top_bar_content p span {
    font-weight: 600;
    color: #ee7d32;
}
.top_bar_setting {
    text-align: right;
    margin-top: 5px;
}
.top_bar_setting i {
    vertical-align: middle;
    color: #3b3b3f;
    font-size: 20px;
    margin-right: 10px;
}
.sign_out_btn {
    border-radius: 50px;
    box-shadow: 3px 3px 5px 0 #f2f2f3;
    border: solid 1px #f2f2f3;
    background-color: var(--white);
    color: #000;
    padding: 7px 32px;
    border: 1px solid transparent;
    transition: linear all 0.4s;
}
.sign_out_btn:hover {
    background-image: linear-gradient(to bottom, #ee7d32, #ff5f49);
    border: 1px solid transparent;
    transition: linear all 0.4s;
}
/*=================header section end====================*/
/*======================================================*/
/*===============company profile outer==================*/
.company_profile_outer {
    padding: 20px 45px;
}
.search_back {
    font-size: 14px;
    color: #252524;
    margin-bottom: 30px !important;
    cursor: pointer;
}
.search_back:hover {
    color: #ee7d32;
}
.search_back:focus {
    color: #ee7d32;
    font-weight: 600;
}
.search_back:active {
    color: #000;
    font-weight: 600;
}
.search_back i {
    vertical-align: middle;
    margin: -3px 5px 0px 0px;
}
/*================compay info outer start====================*/
.company_info {
    padding: 20px 15px 40px 15px !important;
    width: 100%;
    text-align: center;
}
.company_info span {
    font-size: 18px;
    font-weight: 600;
    color: #000000;
    text-decoration: none;
    width: 100%;
    line-height: 20px;
    cursor: pointer;
}
.company_info ul {
    padding: 0;
    margin: 0;
}
.company_info ul li {
    list-style-type: none;
    border-bottom: 3px solid transparent;
    padding: 0px 35px 20px 35px;
    transition: all ease-in-out 0.2s;
    float: left;
}
.company_info ul li:hover {
    color: #ee7d32;
    border-bottom: 3px solid #ee7d32;
}
.company_info span i {
    vertical-align: middle;
    margin-right: 5px;
}

/*dcm_outer*/

.dcm_outer {
    border-radius: 7px;
    box-shadow: 5px 5px 13px 0 #e1e1e1;
    background-color: #000;
    color: #fff;
    margin: 30px 0px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.7) 100%),
        url("../images/dcm-outer.png");
    background-repeat: no-repeat !important;
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;
    background-position: center;
}
.dcm_title {
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 30px;
}
.dcm_right_content {
    margin-bottom: 30px;
}
.dcm_right_content p {
    margin: 0px !important;
    font-size: 20px;
    font-weight: 600;
}
.dcm_right_content p a i {
    vertical-align: middle;
    font-size: 20px;
    margin-right: 5px;
    color: #fff;
}
.dcm_right_content p span {
    margin: 5px 0px 0px 10px;
    background-color: #ff3c3c;
    color: #fff;
    font-size: 13px;
    border-radius: 4px;
    font-family: "Source Sans Pro", sans-serif !important;
    font-weight: 600;
    padding: 5px 15px;
}

.type_head {
    font-size: 14px;
    color: #d9d9d9;
    /* text-transform: uppercase; */
}
.type_head_second {
    font-size: 18px;
    color: #fff;
    margin: 0px;
}
.type_head_second span {
    display: block;
}
.top_exclu {
    line-height: 1.1;
    font-size: 20px;
    font-weight: 600;
    color: #fff;
}
.top_exclusion_text {
    padding-left: 0px;
}
.top_exclusion_text li {
    margin-bottom: 8px;
    list-style: none;
}
.top_exclusion_text li::marker {
    color: #ee7d32;
    font-size: 25px;
    vertical-align: middle;
}
/*=================================================*/
.key_meteics_outer {
}
.key_meteics h1 {
    font-size: 44px;
    font-weight: bold;
    color: #28282c;
}
.key_meteics h1 span {
    font-size: 17px !important;
    font-weight: 600 !important;
    margin-left: 10px;
}
.key_meteics p {
    font-size: 16px;
    line-height: 1.38;
    color: #757474;
    font-family: "Source Sans Pro", sans-serif;
    min-height: 125px;
    margin-right: 20px;
}
.key_meteics ul {
    margin: 0px;
    padding: 0px;
    display: flex;
    flex-wrap: wrap;
}
.key_meteics ul li {
    list-style-type: none;

    width: 280px;
    display: inline-block;
}
.key_matrics_btn span {
    font-size: 13px;
    border-radius: 4px;
    border: solid 1px #4ea9f7;
    margin: 35px 0 30px 0px;
    background-color: transparent;
    transition: linear all 0.4s;
    font-family: "Source Sans Pro", sans-serif !important;
    font-weight: 600;
    color: #4ea9f7;
    padding: 15px 65px;
    cursor: pointer;
}
.key_matrics_btn {
    border: solid 0px #4ea9f7;
    margin: 35px 0 30px 0px;
    background-color: transparent;
}

.key_matrics_btn:hover span {
    color: #fff;
    transition: linear all 0.2s;
    background-color: #4ea9f7;
}
.key_matrics_btn span i {
    vertical-align: middle;
    margin: 0px 5px 0px 0px;
}
/*==================================================*/
.actual_exclusions_side_title h1 {
    font-size: 32px;
    font-weight: 600;
    margin: 0px;
    color: #252524;
}
.actual_exclusions_side_title h1 i {
    vertical-align: middle;
    margin: 0px 10px 0px 0px;
    color: #ee7d32;
    background: #fff;
    border-radius: 50%;
    padding: 8px;
    font-size: 13px;
}
/*===================================================*/
.company_profile_table_outer {
}
.users-table-tabs {
    padding: 0 0px;
    margin-bottom: 25px;
    width: 100%;
}
.tournament-table-tabs .nav-tabs {
    border-bottom: 0;
    list-style-type: none;
    margin-bottom: 25px;
}
.tournament-table-tabs .nav-tabs a {
    border: 0 !important;
    border-bottom-color: currentcolor;
    border-bottom-style: none;
    border-bottom-width: 0px;
    font-size: 20px;
    color: #252524;
    font-weight: 600;
    padding: 0.5em 1em;
}
.tournament-table-tabs .nav-tabs [role="tab"][aria-selected="true"] {
    border-bottom: 2px solid #ee7d32 !important;
    color: #ee7d32;
}
.tournament-table-tabs .tab-content {
    border: none;
}
[role="tab"][aria-selected="true"] {
    background-color: transparent;
}
[role="tab"]:hover:not([aria-selected="true"]) {
    background-color: transparent;
}
a {
    text-decoration: none !important;
    outline: none;
}

/*a:hover, a:active, a:focus {
    text-decoration: none !important;
    outline: none;
    color: #304ffe;
}
*/
.table-user .table > thead > tr > td {
    border-top: 0px !important;
    color: #8f8f8f;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
}
.tr_head {
    border-top: 0px !important;
}
.table-user .table > tbody > tr > th {
    width: 30% !important;

    color: #8f8f8f;
    font-size: 14px;
    font-weight: bold;
}

/*==============================================================*/
.enhanced_sector_detectionr_outer {
}
.enhanced_detection_outer {
    margin-top: 30px;
}
.enhanced_sector_inner {
    margin: 20px 0px;
}
.enhanced_sector_inner p {
    color: #757474;
    font-size: 16px;
    line-height: 1.38;
}
.enhanced_sector_detectionr_outer h5 {
    color: #28282c;
    font-size: 20px;
    font-weight: 600;
}
.enhanced_sector_detectionr_outer h5 span {
    border-bottom: 2px solid #d9d9d9;
    padding-bottom: 8px;
}
.publish_code_outer,
.reason_code_outer {
    margin: 15px 0px;
}
.enhanced_top_content {
    color: #8f8f8f;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
}
.enhanced_site_content {
    color: #252524 !important;
    font-size: 18px;
}
.enhanced_site_content span {
    display: block;
    margin: 10px 0px;
}
.enhanced_site_num {
    font-size: 18px;
    font-weight: 600;
    color: #252524 !important;
}
.enhanced_site_num span {
    color: #ee7d32;
}
.enhanced_detection_outer p {
    color: #757474;
    font-size: 16px;
    line-height: 1.38;
    margin-top: 20px;
}
.enhanced_text {
    padding: 0px 15px 0px 0px;
    margin: 0px 25px 30px 25px;
}
/*==============================*/
/* Add this attribute to the element that needs a tooltip */
[data-tooltip] {
    position: relative;
    z-index: 2;
    cursor: pointer;
}

/* Hide the tooltip content by default */
[data-tooltip]:before,
[data-tooltip]:after {
    visibility: hidden;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
    opacity: 0;
    pointer-events: none;
}

/* Position tooltip above the element */
[data-tooltip]:before {
    position: absolute;
    bottom: 150%;
    left: 50%;
    margin-bottom: 5px;
    margin-left: -80px;
    padding: 7px;
    width: 160px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    background-color: #000;
    background-color: hsla(0, 0%, 20%, 0.9);
    color: #fff;
    content: attr(data-tooltip);
    text-align: center;
    font-size: 14px;
    line-height: 1.2;
}

/* Triangle hack to make tooltip look like a speech bubble */
[data-tooltip]:after {
    position: absolute;
    bottom: 150%;
    left: 50%;
    margin-left: -5px;
    width: 0;
    border-top: 5px solid #000;
    border-top: 5px solid hsla(0, 0%, 20%, 0.9);
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    content: " ";
    font-size: 0;
    line-height: 0;
}

/* Show tooltip content on hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
    visibility: visible;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=100)";
    opacity: 1;
}
/*======================================================*/
.filters p {
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    color: #fff;
}
.filters p::after {
    display: block;
    content: "";
    border-bottom: 2px solid #fff;
    width: 160px;
    margin: -8px 0px 0px 55px;
}

.sidee_bar {
    margin: 80px 15px 0px 15px;
}
.side_bar_outer {
    margin: 30px 0px 0px 0px;
}
.side_bar_outer h5 {
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 15px;
    margin-top: 0px;
}
.side_bar_content {
    background: #fff;
    padding: 10px 5px;
    border-radius: 5px;
    border: solid 1px #d9d9d9;
}
.dcm_side_bar {
    padding: 5px !important;
}
.dem_icon {
    float: left;
}
.dem_icon i {
    color: #ff5f49;
    vertical-align: middle;
    font-size: 20px;
    margin: 5px 5px 0px 0px;
}
.dcm_text {
    background-color: #f0f0f0;
    padding: 5px 0px 5px 10px;
    border-radius: 5px;
    margin-left: 30px;
}
.dcm_text p {
    margin: 0px 5px;
    font-size: 12px;
    color: #252524;
}
.dcm_text span i {
    color: #8f8f8f;
    vertical-align: middle;
    margin-left: 5px;
}
.dcm_email_text p {
    margin-top: 5px;
    font-size: 12px;
    margin-bottom: 5px;
}
.dcm_email_text span i {
    color: #8f8f8f;
    vertical-align: middle;
    margin-left: 15px !important;
    margin-top: -2px !important;
    cursor: pointer;
}
.dcm_criteria_select_optaion,
.dcm_criteria_all_optaion ul {
    padding: 0;
    margin: 0;
}
.dcm_criteria_select_optaion,
.dcm_criteria_all_optaion ul li {
    list-style-type: none;
    display: inline-block;
    margin: 0px;
}

.criteria_select_options ul,
.sidebar_criteria_all_options ul {
    padding: 0;
    margin: 0;
}
.criteria_select_options ul li,
.sidebar_criteria_all_options ul li {
    list-style-type: none;
    display: inline-block;
    margin: 0 2px 4px 0;
}
.side_bar_criteria {
    border: 0px !important;
}
.selected_options_criteria p span i {
    color: #8f8f8f;
    vertical-align: middle;
    margin-left: 5px;
    cursor: pointer;
}
.selected_options_criteria p {
    margin: 0px 0px 5px 0px;
    font-size: 11px;
    color: #252524;
    width: max-content;
    background: #f0f0f0;
    padding: 5px 10px;
    border-radius: 5px;
}
.reset_filter {
    margin: 10px 0px;
}
.reset_filter span {
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
}
.reset_filter span i {
    margin: 0px 5px -2px 0px;
}

/*======================================================*/

/*================*/

@media (min-width: 320px) and (max-width: 480px) {
    .company_profile_outer {
        padding: 10px 0px;
    }
    .box_content_outer {
        padding: 20px 25px;
    }
    /*===========company-info==================*/
    .company_info span {
        font-size: 10px;
    }
    .company_info ul li {
        padding: 0px 5px 20px 0px;
    }
    /*===========company-info==================*/
    /*===============dcm======================*/
    .dcm_title {
        font-size: 20px;
        margin-bottom: 10px;
    }
    .dcm_right_content {
        margin: 20px 0px;
    }
    .dcm_right_content p {
        font-size: 14px;
    }
    .exclusion_outer {
        margin: 10px 0px;
    }
    .type_head_second {
        font-size: 14px;
    }
    .top_exclu {
        font-size: 15px;
    }
    /*===============dcm======================*/
    /*===============key metriks======================*/
    .box_content_outer h2 {
        font-size: 20px;
        margin: 20px 0px;
    }

    .key_meteics p {
        min-height: 0px;
        font-size: 14px;
    }
    .key_meteics h1 {
        font-size: 20px;
    }
    .key_meteics h1 span {
        font-size: 14px !important;
    }
    .key_matrics_btn span {
        margin: 50px 0 20px -5px;
        padding: 15px 20px;
    }
    /*==============exculsion tab===================== */
    .actual_exclusions_side_title h1 {
        font-size: 20px;
    }
    .tournament-table-tabs ul li a {
        font-size: 14px;
    }
    /*======================*/
    /*===============enhanced_sector======================*/
    .enhanced_detection_outer {
        margin-top: 0px;
    }
    .enhanced_sector_inner p {
        font-size: 14px;
    }

    .enhanced_sector_detectionr_outer h5 {
        font-size: 16px;
    }
    .enhanced_site_num {
        font-size: 13px;
    }
    .enhanced_site_content {
        font-size: 13px;
    }
    .enhanced_detection_outer p {
        font-size: 14px;
    }
    .enhanced_text {
        padding: 0px 15px 0px 0px;
        margin: 0px 15px 0px 15px;
    }
    /*======================================*/
}

@media (min-width: 481px) and (max-width: 767px) {
    .company_profile_outer {
        padding: 20px 0px;
    }
    /*===========company-info==================*/
    .company_info span {
        font-size: 13px;
    }
    .company_info ul li {
        padding: 0px 10px 20px 10px;
    }

    /*===========company-info==================*/
    /*===============dcm======================*/
    .dcm_title {
        font-size: 24px;
        margin-bottom: 20px;
    }
    .dcm_right_content {
        margin-bottom: 10px;
    }
    .dcm_right_content p {
        font-size: 14px;
    }
    .exclusion_outer {
        margin: 20px 0px;
    }
    .type_head_second {
        font-size: 14px;
    }
    .top_exclu {
        font-size: 15px;
    }
    /*===============dcm======================*/
    /*===============key metriks======================*/
    .box_content_outer h2 {
        font-size: 24px;
        margin: 20px 0px;
    }

    .key_meteics p {
        min-height: 0px;
        font-size: 14px;
    }
    .key_meteics h1 {
        font-size: 24px;
    }
    .key_meteics h1 span {
        font-size: 14px !important;
    }
    .key_matrics_btn span {
        margin: 50px 0 20px -5px;
        padding: 15px 20px;
    }
    /*==============exculsion tab===================== */
    .actual_exclusions_side_title h1 {
        font-size: 22px;
    }
    .tournament-table-tabs ul li a {
        font-size: 14px;
    }
    /*======================*/
    /*===============enhanced_sector======================*/
    .enhanced_detection_outer {
        margin-top: 0px;
    }
    .enhanced_sector_inner p {
        font-size: 14px;
    }

    .enhanced_sector_detectionr_outer h5 {
        font-size: 18px;
    }
    .enhanced_site_num {
        font-size: 13px;
    }
    .enhanced_site_content {
        font-size: 13px;
    }
    .enhanced_detection_outer p {
        font-size: 14px;
    }
    .enhanced_text {
        padding: 0px 15px 0px 0px;
        margin: 0px 15px 0px 15px;
    }

    /*======================================*/
}

@media (min-width: 768px) and (max-width: 991px) {
    .box_content_outer h2 {
        font-size: 26px;
        margin: 20px 0px;
    }
    .company_profile_outer {
        padding: 10px 10px;
    }
    .company_info span {
        font-size: 14px;
    }
    .company_info ul li {
        padding: 0px 11px 20px 11px;
    }
    /*dcm title*/
    .dcm_title {
        font-size: 24px;
    }
    .dcm_right_content p {
        font-size: 15px;
    }
    .dcm_right_content p a i {
        margin: 0px;
    }
    .dcm_right_content p span {
        margin: 5px 0px 0px 5px;
        padding: 5px 9px;
        font-size: 12px;
    }
    .type_head_second {
        font-size: 14px;
    }
    .top_exclu {
        font-size: 16px;
    }
    /*key metriks*/
    .key_meteics p {
        min-height: 90px;
    }
    .key_meteics ul li {
    }
    .key_meteics h1 {
        font-size: 32px;
    }
    .key_meteics h1 span {
        font-size: 16px !important;
    }
    .key_matrics_btn span {
        margin: 50px 0 20px 0px;
    }

    .actual_exclusions_side_title h1 {
        font-size: 24px;
    }

    .enhanced_detection_outer {
        margin-top: 0px;
    }
    .enhanced_sector_inner p {
        font-size: 14px;
    }

    .enhanced_sector_detectionr_outer h5 {
        font-size: 18px;
    }
    .enhanced_site_num {
        font-size: 13px;
    }
    .enhanced_site_content {
        font-size: 13px;
    }
    .enhanced_detection_outer p {
        font-size: 14px;
    }
    .enhanced_text {
        padding: 0px 15px 0px 0px;
        margin: 0px 15px 0px 15px;
    }
}
/*===============================================*/
@media (min-width: 992px) and (max-width: 1199px) {
}
